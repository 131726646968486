<template>
  <v-container>
    <h1 class="text-center">{{ $t('LEGAL_TERMS_ELTO') }}</h1>
    <v-divider inset class="my-6"></v-divider>
    <h2 class="mb-4 mt-10">{{ $t('LEGAL_TERMS_ELTO_EDITOR') }} :</h2>

    <i18n path="LEGAL_TERMS_ELTO_EDITOR_P1" tag="p">
      <template #clientWebsite>
        <a :href="clientWebsite">{{ clientWebsite }}</a>
      </template>
    </i18n>

    <p>{{ $t('LEGAL_TERMS_ELTO_EDITOR_P2') }}</p>

    <!-- eslint-disable-next-line -->
    <p v-html="$t('LEGAL_TERMS_ELTO_EDITOR_P3')" />

    <h2 class="mb-4 mt-10">{{ $t('LEGAL_TERMS_ELTO_HOSTING') }} :</h2>

    <!-- eslint-disable-next-line -->
    <p v-html="$t('LEGAL_TERMS_ELTO_HOSTING_P1')" />

    <h2 class="mb-4 mt-10">{{ $t('LEGAL_TERMS_ELTO_CONCEPTION') }} :</h2>

    <p>{{ $t('LEGAL_TERMS_ELTO_CONCEPTION_P1') }}</p>

    <h2 class="mb-4 mt-10">{{ $t('LEGAL_TERMS_ELTO_INFO') }} :</h2>

    <i18n path="LEGAL_TERMS_ELTO_INFO_P1" tag="p">
      <template #clientWebsite>
          <a href='/terms-and-conditions'>{{ $t('LEGAL_TERMS_ELTO_CONDITIONS') }}</a>
      </template>
    </i18n>
    <h2 class="mb-4 mt-10">{{ $t('LEGAL_TERMS_ELTO_COPYRIGHT') }} :</h2>

    <i18n path="LEGAL_TERMS_ELTO_COPYRIGHT_P1" tag="p">
      <template #clientName>
        <span>{{ clientName }}</span>
      </template>
    </i18n>

    <i18n path="LEGAL_TERMS_ELTO_COPYRIGHT_P2" tag="p">
      <template #clientName>
        <span>{{ clientName }}</span>
      </template>
    </i18n>

    <h2 class="mb-4 mt-10">{{ $t('LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION') }} :</h2>

    <p>{{ $t('LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P1') }} </p>

    <i18n path="LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P2" tag="p">
      <template #clientServiceMail>
        <a :href="'mailto:' +clientServiceMail" target="_blank">{{ clientServiceMail }}</a>
      </template>
    </i18n>

    <p>{{ $t('LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P3') }}</p>
    <p>{{ $t('LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P4') }}</p>

    <h2 class="mb-4 mt-10">{{ $t('LEGAL_TERMS_ELTO_HYPERTEXT_LINK') }} :</h2>

    <i18n path="LEGAL_TERMS_ELTO_HYPERTEXT_LINK_P1" tag="p">
      <template #clientName>
        <span>{{ clientName }}</span>
      </template>
    </i18n>

    <FabScrollToTop/>

  </v-container>
</template>

<script>
import FabScrollToTop from '@/components/common/FabScrollToTop';
import publicDataMixin from '@/mixins/publicDataMixin';

export default {
  components: {
    FabScrollToTop
  },
  mixins: [publicDataMixin]
};
</script>

<i18n>
{
  "de-DE": {
      "LEGAL_TERMS_ELTO": "Juristische Begriffe",
      "LEGAL_TERMS_ELTO_COPYRIGHT": "COPYRIGHT",
      "LEGAL_TERMS_ELTO_COPYRIGHT_P1": "Der gesamte Inhalt der Seiten der Website ist ausschließliches Eigentum von Worldline und ist durch das Recht auf geistiges Eigentum geschützt. Die Vervielfältigung aller oder eines Teils der auf dieser Website vorhandenen Elemente auf einem beliebigen Medium (einschließlich herunterladbarer Dokumentation in Bezug auf die Website oder die Dienstleistungen, Fotografien oder Ikonographie) sowie jegliche Nutzung des Inhalts der Website, die über die individuelle Konsultation und den privaten Gebrauch hinausgeht, sind strengstens untersagt, es sei denn, es liegt eine ausdrückliche und gemeinsame Genehmigung von Worldline und {clientName}.",
      "LEGAL_TERMS_ELTO_COPYRIGHT_P2": "Jegliche Nutzung einer Seite oder eines Features, einer Marke, eines Firmennamens, eines Zeichens, das Worldline oder {clientName} oder ganz allgemein eines auf der Website erscheinenden Kennzeichens kann zu rechtlichen Schritten führen.",
      "LEGAL_TERMS_ELTO_CONDITIONS": "Bedingungen und Konditionen",
      "LEGAL_TERMS_ELTO_CONCEPTION": "ENTWURF / REALISIERUNG",
      "LEGAL_TERMS_ELTO_CONCEPTION_P1": "Worldline France, eine vereinfachte Aktiengesellschaft mit einem Kapital von 58.061.383,17 Euro, mit Sitz in 80 quai Voltaire “Immeuble River Ouest“ 95870 BEZONS, eingetragen unter der Nummer 509 750 105 RCS Pontoise, vertreten durch Madame Claude France, ordnungsgemäß bevollmächtigt für die Zwecke dieses Dokuments.",
      "LEGAL_TERMS_ELTO_EDITOR": "SOFTWAREHERSTELLER-EDITOR",
      "LEGAL_TERMS_ELTO_EDITOR_P1": "Die {clientWebsite} wird von der Firma Worldline veröffentlicht.",
      "LEGAL_TERMS_ELTO_EDITOR_P2": "Worldline France, eine vereinfachte Aktiengesellschaft mit einem Kapital von 58.061.383,17 Euro, mit Sitz in 80 quai Voltaire “Immeuble River Ouest“ 95870 BEZONS, eingetragen unter der Nummer 509 750 105 RCS Pontoise, vertreten durch Madame Claude France, ordnungsgemäß bevollmächtigt für die Zwecke dieses Dokuments.",
      "LEGAL_TERMS_ELTO_EDITOR_P3": "Interkommunale MwSt.-Nummer: FR 01 378 901 946 <br/>Telefon: +33 (0)1 73 26 00 00",
      "LEGAL_TERMS_ELTO_HOSTING": "WEB-HOSTING",
      "LEGAL_TERMS_ELTO_HOSTING_P1": "Worldline verwaltet die Website, die in der öffentlichen Cloud Amazon Web Services gehostet wird (<a href='https://aws.amazon.com/fr/local/france/paris/' target='_blank'>Datenzentrum in Frankreich</a>).",
      "LEGAL_TERMS_ELTO_HYPERTEXT_LINK": "HYPERTEXT LINK",
      "LEGAL_TERMS_ELTO_HYPERTEXT_LINK_P1": "Die Einrichtung von Links zur Homepage oder zu einer der Seiten der Website unterliegt der vorherigen schriftlichen Zustimmung von {clientName}.",
      "LEGAL_TERMS_ELTO_INFO": "ALLGEMEINE INFORMATIONEN",
      "LEGAL_TERMS_ELTO_INFO_P1": "Der Besuch dieser Website unterliegt der Einhaltung der {clientWebsite}.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION": "EINSCHRÄNKUNG DER VERANTWORTUNG",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P1": "Der Herausgeber ist bestrebt, den Benutzern und Besuchern der Website zuverlässige und geprüfte Informationen zur Verfügung zu stellen.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P2": "Trotz aller Sorgfalt kann die Website jedoch Ungenauigkeiten, Aktualisierungsfehler oder Irrtümer enthalten. Die Nutzer und Besucher der Website können den Herausgeber auf Auslassungen oder Fehler per E-Mail an folgende Adresse hinweisen {clientServiceMail}.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P3": "Jeder heruntergeladene Inhalt und jede Nutzung der Website unterliegt der vollen Verantwortung des Nutzers oder Besuchers der Website.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P4": "Infolgedessen können weder der Herausgeber noch der Host-Provider für direkte oder indirekte Schäden verantwortlich gemacht werden, insbesondere nicht für materielle Schäden, Daten- oder Programmverluste oder finanzielle Schäden, die sich aus dem Zugang oder der Nutzung dieser Website oder der mit ihr verbundenen Websites ergeben."
    },
  "en-GB": {
    "LEGAL_TERMS_ELTO": "Legal terms",
    "LEGAL_TERMS_ELTO_COPYRIGHT": "COPYRIGHT",
    "LEGAL_TERMS_ELTO_COPYRIGHT_P1": "All of the content on the pages of the Site is the exclusive property of Worldline, and are protected by intellectual property law. The reproduction of all or part of the elements present on this Site on any medium whatsoever (including downloadable documentation relating to the Site or the Services, photographs or iconography), as well as any use of the content of the Site other than individual consultation and private are strictly prohibited, except with the express and joint authorization of Worldline and {clientName}.",
    "LEGAL_TERMS_ELTO_COPYRIGHT_P2": "Any use of a page or a feature, of a brand, company name, sign, belonging to Worldline or {clientName} or more generally of a distinctive sign appearing on the Site may give rise to legal proceedings.",
    "LEGAL_TERMS_ELTO_CONDITIONS": "Terms and Conditions",
    "LEGAL_TERMS_ELTO_CONCEPTION": "DESIGN / REALIZATION",
    "LEGAL_TERMS_ELTO_CONCEPTION_P1": "Worldline France, a simplified joint stock company with a capital of 58,061,383.17 euros, whose registered office is at 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, registered under number 509 750 105 RCS Pontoise, represented by Madame Claude France, duly authorized for the purposes hereof.",
    "LEGAL_TERMS_ELTO_EDITOR": "SOFTWARE COMPANY EDITOR",
    "LEGAL_TERMS_ELTO_EDITOR_P1": "The {clientWebsite} website is published by the company Worldline.",
    "LEGAL_TERMS_ELTO_EDITOR_P2": "Worldline France, a simplified joint stock company with a capital of 58,061,383.17 euros, whose registered office is at 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, registered under number 509 750 105 RCS Pontoise, represented by Madame Claude France, duly authorized for the purposes hereof.",
    "LEGAL_TERMS_ELTO_EDITOR_P3": "Intercommunal VAT number: FR 01 378 901 946 <br/>Phone: +33 (0)1 73 26 00 00",
    "LEGAL_TERMS_ELTO_HOSTING": "WEB HOSTING",
    "LEGAL_TERMS_ELTO_HOSTING_P1": "Worldline manages the Site, hosted on the Public Cloud Amazon Web Services (<a href='https://aws.amazon.com/fr/local/france/paris/' target='_blank'>Data Centre located in France</a>).",
    "LEGAL_TERMS_ELTO_HYPERTEXT_LINK": "HYPERTEXT LINK",
    "LEGAL_TERMS_ELTO_HYPERTEXT_LINK_P1": "The creation of links to the home page or one of the pages of the Site is subject to {clientName}'s prior written acceptance.",
    "LEGAL_TERMS_ELTO_INFO": "INFORMATIONS GENERALES",
    "LEGAL_TERMS_ELTO_INFO_P1": "Visiting this Site is subject to compliance with the {clientWebsite}.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION": "RESPONSIBILITY LIMITATION",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P1": "The Editor makes every effort to provide reliable and verified information to users and visitors of the Site.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P2": "However, despite all the care taken, the Site may contain inaccuracies, errors in updating or errors. Users and visitors to the Site can notify the Editor of any omissions or errors by email to {clientServiceMail}.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P3": "Any downloaded content and any use of the Site is under the full responsibility of the user or visitor to the Site.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P4": "Consequently, in any case, the Editor, nor the host provider, can’t be held responsible for direct or indirect damage, and in particular material damage, loss of data or program, financial damage, resulting from the access or use of this Site or any sites linked to it."
  },
  "fr-FR": {
    "LEGAL_TERMS_ELTO": "Mentions légales",
    "LEGAL_TERMS_ELTO_COPYRIGHT": "DROIT D'AUTEUR, COPYRIGHT",
    "LEGAL_TERMS_ELTO_COPYRIGHT_P1": "L’ensemble des programmes présents dans les pages du Site sont la propriété exclusive de Worldline, et sont protégés par le droit de la propriété intellectuelle. La reproduction de tout ou partie des éléments présents sur ce Site sur quelque support que ce soit (y compris la documentation téléchargeable relative au Site ou aux Servives, les photographies ou iconographies), ainsi que toute utilisation du contenu du Site autre que la consultation individuelle et privée sont formellement interdites, sauf autorisation expresse et commune de Worldline et de {clientName}.",
    "LEGAL_TERMS_ELTO_COPYRIGHT_P2": "Toute reprise d’une page ou d’une fonctionnalité, d’une marque, dénomination sociale, enseigne, appartenant à Worldline ou {clientName} ou plus généralement d’un signe distinctif figurant sur le Site pourra donner lieu à des poursuites judiciaires.",
    "LEGAL_TERMS_ELTO_CONDITIONS": "Conditions d'Utilisation",
    "LEGAL_TERMS_ELTO_CONCEPTION": "CONCEPTION / REALISATION",
    "LEGAL_TERMS_ELTO_CONCEPTION_P1": "Worldline France, société par actions simplifiée au capital de 58 061 383,17 euros, dont le siège social est au 80 quai Voltaire « Immeuble River Ouest » 95870 BEZONS, enregistrée sous le numéro 509 750 105 RCS Pontoise, représentée par Madame Claude France, dûment habilitée aux fins des présentes.",
    "LEGAL_TERMS_ELTO_EDITOR": "EDITEUR",
    "LEGAL_TERMS_ELTO_EDITOR_P1": "Le site Internet {clientWebsite} (ci-après désigné le « Site ») est édité par la société Worldline.",
    "LEGAL_TERMS_ELTO_EDITOR_P2": "Worldline France, société par actions simplifiée au capital de 58 061 383,17 euros, dont le siège social est au 80 quai Voltaire « Immeuble River Ouest » 95870 BEZONS, enregistrée sous le numéro 509 750 105 RCS Pontoise, représentée par Madame Claude France, dûment habilitée aux fins des présentes.",
    "LEGAL_TERMS_ELTO_EDITOR_P3": "Numéro de TVA intra-communautaire : FR 01 378 901 946<br/>Téléphone : +33 (0)1 73 26 00 00",
    "LEGAL_TERMS_ELTO_HOSTING": "HEBERGEUR",
    "LEGAL_TERMS_ELTO_HOSTING_P1": "L’entreprise Worldline assure l’infogérance du Site, hébergé sur le Cloud Public Amazon Web Services (<a href='https://aws.amazon.com/fr/local/france/paris/' target='_blank'>Data Centre en France</a>).",
    "LEGAL_TERMS_ELTO_HYPERTEXT_LINK": "LIENS HYPERTEXTES",
    "LEGAL_TERMS_ELTO_HYPERTEXT_LINK_P1": "La création de liens vers la page d'accueil ou l'une des pages du Site est subordonnée à l’acceptation écrite et préalable de {clientName}.",
    "LEGAL_TERMS_ELTO_INFO": "INFORMATIONS GENERALES",
    "LEGAL_TERMS_ELTO_INFO_P1": "La visite de ce Site est soumise au respect des {clientWebsite}.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION": "LIMITATION DE RESPONSABILITE",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P1": "L’Editeur met tout en œuvre pour offrir des informations fiables et vérifiées aux utilisateurs et visiteurs du Site.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P2": "Cependant, malgré tous les soins apportés, le Site peut comporter des inexactitudes, des défauts de mise à jour ou des erreurs. Les utilisateurs et visiteurs du Site peuvent faire part à l’Editeur d’éventuelles omissions ou erreurs par courriel adressé à {clientServiceMail}.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P3": "Tout contenu téléchargé et toute utilisation du Site se fait sous la pleine et entière responsabilité de l’utilisateur ou visiteur du Site.",
    "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P4": "En conséquence, en aucun cas, l’Editeur, ni l’hébergeur, ne pourront être tenus responsables des dommages directs ou indirects, et notamment préjudice matériel, perte de données ou de programme, préjudice financier, résultant de l’accès ou utilisation de ce Site ou de tous sites qui lui sont liés."
  },
    "nl-NL": {
      "LEGAL_TERMS_ELTO": "Juridische kennisgeving",
      "LEGAL_TERMS_ELTO_COPYRIGHT": "AUTEURSRECHTEN",
      "LEGAL_TERMS_ELTO_COPYRIGHT_P1": "Alle programma's op de pagina's van de site zijn het exclusieve eigendom van Worldline en worden beschermd door de wetgeving inzake intellectuele eigendom. De reproductie van alle of een deel van de elementen op deze Site op welk medium dan ook (inclusief downloadbare documentatie met betrekking tot de Site of Diensten, foto's of iconografieën), evenals elk gebruik van de inhoud van de Site anders dan individuele raadpleging en privé-consultatie zijn ten strengste verboden, behalve met de uitdrukkelijke en gezamenlijke toestemming van Worldline en {clientName}.",
      "LEGAL_TERMS_ELTO_COPYRIGHT_P2": "Elk gebruik van een pagina of een functie, van een merk, bedrijfsnaam, teken dat toebehoort aan Worldline of {clientName} of meer in het algemeen van een onderscheidend teken dat op de Site verschijnt, kan aanleiding geven tot juridische procedures.",
      "LEGAL_TERMS_ELTO_CONDITIONS": "Gebruiksvoorwaarden",
      "LEGAL_TERMS_ELTO_CONCEPTION": "ONTWERP / PRODUCTIE",
      "LEGAL_TERMS_ELTO_CONCEPTION_P1": "Worldline France, vereenvoudigde naamloze vennootschap met een kapitaal van 58.061.383,17 euro, met hoofdkantoor op 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, geregistreerd onder nummer 509 750 105 RCS Pontoise, vertegenwoordigd door Madame Claude France, naar behoren gemachtigd voor de doeleinden hiervan.",
      "LEGAL_TERMS_ELTO_EDITOR": "EDITOR",
      "LEGAL_TERMS_ELTO_EDITOR_P1": "De website {clientWebsite} (hierna de «Site» genoemd) wordt uitgegeven door het bedrijf Worldline",
      "LEGAL_TERMS_ELTO_EDITOR_P2": "Worldline France, vereenvoudigde naamloze vennootschap met een kapitaal van 58.061.383,17 euro, met hoofdkantoor op 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, geregistreerd onder nummer 509 750 105 RCS Pontoise, vertegenwoordigd door Madame Claude France, naar behoren gemachtigd voor de doeleinden hiervan.",
      "LEGAL_TERMS_ELTO_EDITOR_P3": "Intracommunautair BTW-nummer: FR 01 378 901 946<br/>Telefoon: +33 (0)1 73 26 00 00",
      "LEGAL_TERMS_ELTO_HOSTING": "GASTHEER",
      "LEGAL_TERMS_ELTO_HOSTING_P1": "Worldline biedt outsourcingdiensten voor de Site, gehost op de Amazon Web Services Public Cloud  (<a href='https://aws.amazon.com/fr/local/france/paris/' target='_blank'> Datacenter in Frankrijk </a>).",
      "LEGAL_TERMS_ELTO_HYPERTEXT_LINK": "HYPERTEKSTLINKS",
      "LEGAL_TERMS_ELTO_HYPERTEXT_LINK_P1": "Het maken van links naar de startpagina of een van de pagina's van de site is onderworpen aan de voorafgaande schriftelijke aanvaarding van {clientName}.",
      "LEGAL_TERMS_ELTO_INFO": "ALGEMENE INFORMATIE",
      "LEGAL_TERMS_ELTO_INFO_P1": "Het bezoeken van deze site is onderworpen aan naleving van {clientWebsite}.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION": "BEPERKING VAN AANSPRAKELIJKHEID",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P1": "De Uitgever doet er alles aan om betrouwbare en geverifieerde informatie te verstrekken aan gebruikers en bezoekers van de Site.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P2": "Ondanks alle zorgvuldigheid kan de Site echter onnauwkeurigheden, gebrek aan updates of fouten bevatten. Gebruikers en bezoekers van de Site kunnen de Uitgever op de hoogte stellen van eventuele omissies",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P3": "Alle gedownloade inhoud en elk gebruik van de site valt onder de volledige verantwoordelijkheid van de gebruiker of bezoeker van de site.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P4": "Bijgevolg kan de Uitgever, noch de host, in geen geval aansprakelijk worden gesteld voor directe of indirecte schade, en in het bijzonder materiële schade, verlies van gegevens of programma, financiële schade, die voortvloeit uit de toegang tot of het gebruik van deze Site of enige sites daaraan gekoppeld."
    },
    "it-IT": {
      "LEGAL_TERMS_ELTO": "Termini legali",
      "LEGAL_TERMS_ELTO_COPYRIGHT": "AUTEURSRECHTEN",
      "LEGAL_TERMS_ELTO_COPYRIGHT_P1": "Alle programma's op de pagina's van de site zijn het exclusieve eigendom van Worldline en worden beschermd door de wetgeving inzake intellectuele eigendom. De reproductie van alle of een deel van de elementen op deze Site op welk medium dan ook (inclusief downloadbare documentatie met betrekking tot de Site of Diensten, foto's of iconografieën), evenals elk gebruik van de inhoud van de Site anders dan individuele raadpleging en privé-consultatie zijn ten strengste verboden, behalve met de uitdrukkelijke en gezamenlijke toestemming van Worldline en {clientName}.",
      "LEGAL_TERMS_ELTO_COPYRIGHT_P2": "Elk gebruik van een pagina of een functie, van een merk, bedrijfsnaam, teken dat toebehoort aan Worldline of {clientName} of meer in het algemeen van een onderscheidend teken dat op de Site verschijnt, kan aanleiding geven tot juridische procedures.",
      "LEGAL_TERMS_ELTO_CONDITIONS": "Termini e Condizioni",
      "LEGAL_TERMS_ELTO_CONCEPTION": "PROGETTAZIONE / REALIZZAZIONE",
      "LEGAL_TERMS_ELTO_CONCEPTION_P1": "Worldline France, a simplified joint stock company with a capital of 58,061,383.17 euros, whose registered office is at 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, registered under number 509 750 105 RCS Pontoise, represented by Madame Claude France, duly authorized for the purposes hereof.",
      "LEGAL_TERMS_ELTO_EDITOR": "EDITORE DELL'AZIENDA DI SOFTWARE",
      "LEGAL_TERMS_ELTO_EDITOR_P1": "Il sito web {clientWebsite} è pubblicato dalla società Worldline.",
      "LEGAL_TERMS_ELTO_EDITOR_P2": "Worldline France, società per azioni semplificata con un capitale di 58.061.383,17 euro, con sede legale in 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, iscritta al numero 509 750 105 RCS Pontoise, rappresentata da Madame Claude France, debitamente autorizzata alla scopi del presente documento.",
      "LEGAL_TERMS_ELTO_EDITOR_P3": "Partita IVA intercomunale: FR 01 378 901 946 <br/>Telefono: +33 (0)1 73 26 00 00",
      "LEGAL_TERMS_ELTO_HOSTING": "WEB HOSTING",
      "LEGAL_TERMS_ELTO_HOSTING_P1": "Worldline gestisce il Sito, ospitato sul cloud pubblico Amazon Web Services (<a href='https://aws.amazon.com/fr/local/france/paris/' target='_blank'>Data Center situato in Francia< /a>).",
      "LEGAL_TERMS_ELTO_HYPERTEXT_LINK": "COLLEGAMENTO IPERTESTUALE",
      "LEGAL_TERMS_ELTO_HYPERTEXT_LINK_P1": "La creazione di collegamenti alla home page o ad una delle pagine del Sito è soggetta alla previa accettazione scritta di {clientName}.",
      "LEGAL_TERMS_ELTO_INFO": "INFORMAZIONI GENERALI",
      "LEGAL_TERMS_ELTO_INFO_P1": "La visita di questo sito è soggetta al rispetto del {clientWebsite}.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION": "LIMITAZIONE DI RESPONSABILITÀ",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P1": "L'Editore fa ogni sforzo per fornire informazioni affidabili e verificate agli utenti e ai visitatori del Sito.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P2": "Tuttavia, nonostante tutta la cura posta, il Sito potrebbe contenere imprecisioni, errori di aggiornamento o errori. Gli utenti e i visitatori del Sito possono segnalare all'Editore eventuali omissioni o errori tramite e-mail a {clientServiceMail}.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P3": "Qualsiasi contenuto scaricato e qualsiasi utilizzo del Sito è sotto la piena responsabilità dell'utente o visitatore del Sito.",
      "LEGAL_TERMS_ELTO_RESPONSIBILITY_LIMITATION_P4": "Di conseguenza, in nessun caso, l'editore, né il fornitore ospitante, non possono essere ritenuti responsabili per danni diretti o indiretti, e in particolare danni materiali, perdita di dati o di programmi, danni finanziari, derivanti dall'accesso o dall'uso di questo Sito o eventuali siti ad esso collegati."
    }
}
</i18n>