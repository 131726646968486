<template>
  <v-container>
    <component :is="componentName">
    </component>
    <FabScrollToTop/>
  </v-container>
</template>

<script>
import FabScrollToTop from '@/components/common/FabScrollToTop';
import AccorLegalTerms from '@/components/common/legal-terms/AccorLegalTerms';
import DemoLegalTerms from '@/components/common/legal-terms/DemoLegalTerms';
import FrindigogpLegalTerms from '@/components/common/legal-terms/FrindigogpLegalTerms';
import FrindigosigcLegalTerms from '@/components/common/legal-terms/FrindigosigcLegalTerms';
import IndigoLegalTerms from '@/components/common/legal-terms/IndigoLegalTerms';
import MpsLegalTerms from '@/components/common/legal-terms/MpsLegalTerms';
import IqeraLegalTerms from '@/components/common/legal-terms/IqeraLegalTerms';
import BloctelLegalTerms from '@/components/common/legal-terms/BloctelLegalTerms';
import environmentService from '@/services/environmentService';

export default {
  components: {
    FabScrollToTop,
    IndigoLegalTerms,
    FrindigosigcLegalTerms,
    FrindigogpLegalTerms,
    DemoLegalTerms,
    AccorLegalTerms,
    MpsLegalTerms,
    IqeraLegalTerms,
    BloctelLegalTerms
  },
  data: () => ({
    realm: environmentService.realm,
    availableLegalTerms: [
      'IndigoLegalTerms',
      'FrindigosigcLegalTerms',
      'FrindigogpLegalTerms',
      'DemoLegalTerms',
      'AccorLegalTerms',
      'MpsLegalTerms',
      'IqeraLegalTerms',
      'BloctelLegalTerms'
    ]
  }),
  computed: {
    componentName () {
      const alnumRealmName = this.realm.replace(/\W/g, '');
      return alnumRealmName.charAt(0).toUpperCase() + alnumRealmName.slice(1) + 'LegalTerms';
    }
  },
  beforeMount () {
    if (!this.availableLegalTerms.includes(this.componentName)) {
      this.$router.push({ name: 'Error' });
    }
  }
};
</script>