<template>
    <v-btn
        :title="$t('SCROLL_TO_TOP')"
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
    >
        <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
</template>

<script>
export default {
  data: () => ({
    fab: false
  }),
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 300;
    },
    toTop () {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped>
  .v-btn {
    margin-bottom: 6rem;
  }
</style>
