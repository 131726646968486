import { render, staticRenderFns } from "./BloctelPersonalData.vue?vue&type=template&id=17e022c4&scoped=true&"
import script from "./BloctelPersonalData.vue?vue&type=script&lang=js&"
export * from "./BloctelPersonalData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17e022c4",
  null
  
)

/* custom blocks */
import block0 from "./BloctelPersonalData.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VContainer,VDivider})
