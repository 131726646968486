<template>
  <v-container>
      <h1 class="text-center">{{ $t('CGU') }}</h1>
      <h1 class="text-center">{{ $t('CGU_WBPM') }}</h1>
      <h1 class="text-center">{{ $t('CGU_VERSION') }}</h1>
      <v-divider inset class="my-6"></v-divider>

      <ul class="list-style-type-none">
        <li v-for="(section, index) in $t('TAC_WBPM')" :key="index">

          <h2 class="mb-4 mt-10">{{ section.title }}</h2>

          <ul class="list-style-type-none" v-if="section.subparagraphs_n1">
            <li
              v-for="(subparagraph_n1, index) in section.subparagraphs_n1"
              :key="index"
            >
              <h3 class="mb-2" v-html="subparagraph_n1.title"></h3>

              <ul
                class="list-style-type-none"
                v-if="subparagraph_n1.subparagraphs_n2"
              >
                <li
                  v-for="(
                    subparagraphs_n2, index
                  ) in subparagraph_n1.subparagraphs_n2"
                  :key="index"
                >
                  <h4 class="mb-4" v-html="subparagraphs_n2.title"></h4>
                  <p
                    v-for="(line, index) in subparagraphs_n2.lines_1"
                    :key="index"
                    v-html="line"
                  >
                  </p>
                </li>
              </ul>

              <p
                v-for="(line, index) in subparagraph_n1.lines_1"
                :key="'sub1-line1-index' + index"
                v-html="line"
              ></p>
              <ul v-if="subparagraph_n1.list_1">
                <li
                  v-for="(item, index) in subparagraph_n1.list_1"
                  :key="index"
                >
                  <p v-html="item"></p>
                </li>
              </ul>
              <p
                v-for="(line, index) in subparagraph_n1.lines_2"
                :key="'sub1-line2-index' + index"
                v-html="line"
              ></p>
              <ul v-if="subparagraph_n1.list_2">
                <li
                  v-for="(item, index) in subparagraph_n1.list_2"
                  :key="index"
                >
                  <p v-html="item"></p>
                </li>
              </ul>
            </li>
          </ul>

          <p
            v-for="(line, index) in section.lines_1"
            :key="'section1-line1-index' + index"
            v-html="line.replace('{clientWebsite}',clientWebsite).replace('{clientITName}',clientITName).replace('{clientITAdresse}',clientITAdresse).replace('{clientITNum}',clientITNum)"
          ></p>
          <ul v-if="section.list_1">
            <li v-for="(item, index) in section.list_1" :key="index">
              <p v-html="item"></p>
            </li>
          </ul>
          <p
            v-for="(line, index) in section.lines_2"
            :key="'section1-line2-index' + index"
            v-html="line"
          ></p>
          <ul v-if="section.list_2">
            <li v-for="(item, index) in section.list_2" :key="index">
              <p v-html="item"></p>
            </li>
          </ul>
        </li>
      </ul>
    <FabScrollToTop />
  </v-container>
</template>

<script>
import FabScrollToTop from '@/components/common/FabScrollToTop';
import publicDataMixin from '@/mixins/publicDataMixin';

export default {
  components: {
    FabScrollToTop
  },
  mixins: [publicDataMixin]
};
</script>

<style scoped>
.list-style-type-none {
  list-style-type: none;
}
</style>

<i18n>
{
  "de-DE": {
    "CGU": "ALLGEMEINE BEDINGUNGEN FÜR DIE NUTZUNG DES DIENSTES",
    "CGU_VERSION": "Version Nummer 1",
    "CGU_WBPM": "WL BILL PAY & MATCH",
    "PERSONAL_DATA": "Persönliche Daten",
    "TAC_WBPM": [
      {
        "title": "1. DEFINITIONEN",
        "lines_1": [
          "In diesen Allgemeinen Geschäftsbedingungen für die Nutzung der <strong>Online-Rechnungseinsichts- und Zahlungsdienstes mit der Bezeichnung « WL Bill Pay & Match (WL BPM) »</strong> (nachfolgend die « <strong>Allgemeinen Geschäftsbedingungen</strong> ») die auf der Website {clientWebsite} (nachfolgend die « Site »), verfügbar sind, haben Wörter oder Ausdrücke, die mit einem Großbuchstaben beginnen, folgende Bedeutung :",
          "« <strong>Besondere Nutzungsbedingungen</strong> » oder « <strong>CPU</strong> » : bezeichnet die besonderen Nutzungsbedingungen, die für bestimmte Services gelten und vom Nutzer vor der Nutzung der betreffenden Services (z.B. WL SPS & WL Account Based Payment für die veranlasste Überweisung) akzeptiert werden müssen.",
          "« <strong>Gläubiger</strong> » : bezeichnet das Unternehmen, das den Dienst für die Bereitstellung und Bezahlung von Rechnungen nutzt, die von den Schuldnern zu begleichen sind. Im Falle der Website ist der Gläubiger die Gesellschaft {clientITName}, eine vereinfachte Aktiengesellschaft mit einem einzigen Aktionär, deren Sitz sich in {clientITAdresse} befindet und die im Handels- und Gesellschaftsregister von Nanterre unter der Nummer {clientITNum} eingetragen ist.",
          "« <strong>Schuldner</strong> » : ist der Kunde, eine natürliche oder juristische Person, des Zahlungsempfängers und Schuldners des letzteren. Der Schuldner hat ein Benutzerkonto auf der Website erstellt und nutzt den Dienst, indem er sich über eine vom Gläubiger übermittelte Kennung identifiziert.",
          "« <strong>Anbieter</strong> » : bezeichnet das Unternehmen Worldline, das für die Entwicklung und den Betrieb des WL Bill Pay & Match (WL BPM) Service verantwortlich ist.",
          "« <strong>Dienst</strong> » : bezeichnet den WL Bill Pay & Match (WL BPM) Service in seiner aktuellen und zukünftigen Version sowie alle zukünftigen Entwicklungen.",
          "« <strong>Geheimer Schlüssel</strong> » : ist die vom Zahlungsempfänger angegebene Kennung des Schuldners.",
          "« <strong>Benutzer</strong> » : bezeichnet einen Schuldner oder jede natürliche Person, die im Namen eines Schuldners handelt (Angestellter, Wirtschaftsprüfungsgesellschaft, zugelassener Verwaltungsverband, zugelassenes Verwaltungszentrum, Holdinggesellschaft usw.), die ein Benutzerkonto auf der Website erstellt hat und den Dienst nutzt."
        ]
      },
      {
        "title": "2. ZWECK DER GC UND AKTUELLE VERSION",
        "lines_1": [
          "Der Zweck dieser AGB ist es, die Bedingungen festzulegen, unter denen die Nutzer auf den Dienst zugreifen und ihn nutzen können.",
          "Jeder Nutzer, der auf den Dienst zugreift, verpflichtet sich, die vorliegenden AGB, gegebenenfalls ergänzt durch die CPUs der betreffenden Dienste, uneingeschränkt einzuhalten. Diese CPUs werden dem Nutzer vor der Nutzung der Dienste zur ausdrücklichen und vorherigen Annahme mitgeteilt.",
          "Maßgeblich ist die Version der AGB, auf die Sie direkt zugreifen können, indem Sie auf den Link « AGB » am unteren Rand der Website klicken. Das Gleiche gilt für alle CPUs, die unter der Adresse der Dienste online zugänglich sind. Alle Nutzer müssen sich daher auf die Version beziehen, die zum Zeitpunkt ihres Zugriffs und ihrer Nutzung der Dienste online verfügbar ist.",
          "Der Nutzer wird ausdrücklich darauf hingewiesen, dass die einzig maßgebliche Version der AGB des Dienstes die auf der Website online gestellte ist, die er uneingeschränkt anerkennt und akzeptiert, indem er sich verpflichtet, sie bei jeder Verbindung systematisch zu lesen."
        ]
      },
      {
        "title": "3. DAUER",
        "lines_1": [
          "Diese AGB sind für den Nutzer und den Dienstanbieter für die Dauer der Nutzung des Dienstes verbindlich."
        ]
      },
      {
        "title": "4. GARANTIEN UND HAFTUNG",
        "subparagraphs_n1": [
          {
            "title": "4.1 Garantien",
            "lines_1": [
              "Der Dienstanbieter ist als technischer Dienstleister für den Betrieb und die Verwaltung der Website und des Dienstes zuständig.",
              "In diesem Zusammenhang wird festgelegt, dass alle Informationen zu den Rechnungen des Zahlungsempfängers, die im Dienst erscheinen, direkt vom Zahlungsempfänger bereitgestellt werden, der sie über spezielle APIs eingeben, ändern und aktualisieren kann. Der Dienstanbieter kann daher in keiner Weise für die Richtigkeit dieser Informationen verantwortlich gemacht werden."
            ]
          },
          {
            "title": "4.2 Verantwortung",
            "subparagraphs_n2": [
              {
                "title": "4.2.1 Haftung des Anbieters",
                "lines_1": [
                  "Bei Nichteinhaltung der AGB durch den Nutzer und/oder eine mit ihm direkt oder indirekt verbundene Person haften der Dienstanbieter und der Zahlungsempfänger nicht für Schäden, die der Nutzer oder eine mit ihm verbundene Person erleidet.",
                  "Der Nutzer erkennt an, dass es zu Störungen in den Netzen und beim Betrieb der Website kommen kann.",
                  "Der Dienstleister und der Zahlungsempfänger können nicht haftbar gemacht werden, wenn die Nutzung aller oder eines Teils der Funktionen der Website und/oder des Dienstes aus irgendeinem Grund nicht möglich ist.",
                  "Dennoch kann der Dienstanbieter unter den Bedingungen des Gewohnheitsrechts für direkte und vorhersehbare Schäden, die der Nutzer erleidet, gemäß den Bestimmungen des Bürgerlichen Gesetzbuchs haftbar gemacht werden, insbesondere im Falle eines Verstoßes des Dienstanbieters gegen seine Sicherheits- und Vertraulichkeitsverpflichtungen in Bezug auf vertrauliche und/oder personenbezogene Daten (siehe Seite « <a href='/personal-data'>Persönliche Daten</a> » am Ende der Website)."
                ]
              },
              {
                "title": "4.2.2 Verantwortung des Nutzers",
                "lines_1": [
                  "Die gesamte Hardware und Software, die für den Zugang und die Nutzung des Dienstes erforderlich ist, liegt in der alleinigen Verantwortung des Nutzers, der somit allein für das ordnungsgemäße Funktionieren seiner Geräte und seines Internetzugangs verantwortlich ist. Es obliegt daher dem Nutzer, alle geeigneten Maßnahmen zu ergreifen, um seine eigenen Daten, Computersysteme und/oder Software vor einer Kontamination durch Viren zu schützen.",
                  "Der Benutzer erstellt ein Konto und ein Passwort. Der Nutzer ist daher für die Vertraulichkeit seines Kontos und seiner Kennungen sowie für alle Vorgänge verantwortlich, die über dieses Konto durchgeführt werden können.",
                  "Der Nutzer ist zu keinem Zeitpunkt berechtigt, das Konto eines Dritten ohne dessen Zustimmung zu nutzen.",
                  "Im Falle eines Verstoßes des Nutzers gegen die Regeln der Vertraulichkeit des Kontos, der Verwendung des Passworts und/oder des Kontos, die nicht mit diesen AGB übereinstimmen, haften der Anbieter und der Zahlungsempfänger nicht für Schäden oder Verluste, die der Nutzer, der Kontoinhaber, erleidet.",
                  "Der Nutzer erklärt sich damit einverstanden, niemandem zu gestatten, diese Website und den Dienst direkt oder indirekt in einer Weise zu nutzen, die die Sicherheit dieser Website oder von Diensten, Systemressourcen, Konten, Servern, Netzwerken usw. beschädigen, deaktivieren, überlasten, beeinträchtigen, stören oder in anderer Weise missbrauchen könnte.",
                  "Der Nutzer verpflichtet sich, den Dienstanbieter unverzüglich über jede unbefugte Nutzung seines Kontos oder jede andere Verletzung der Sicherheit zu informieren."
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "5. GEISTIGES EIGENTUM",
        "lines_1": [
          "Alle Elemente der Website und des Dienstes sind durch geistige Eigentumsrechte geschützt, zu deren Nutzung der Dienstanbieter berechtigt ist.",
          "Die vorliegenden Allgemeinen Bedingungen dürfen nicht so ausgelegt werden, dass sie eine Übertragung von Rechten an diesen Elementen auf den Nutzer implizieren. Jede vollständige oder teilweise Reproduktion oder Darstellung der Website oder des Dienstes kann zivil- und strafrechtlich als Fälschung geahndet werden.",
          "Insbesondere erkennt der Nutzer in Bezug auf den Dienst an, dass die Daten, aus denen der Dienst besteht, geschützt sind, und erklärt sich damit einverstanden, den Inhalt der Datenbanken, aus denen der Dienst besteht, weder ganz noch teilweise für andere Zwecke als die Durchführung des Dienstes wiederzuverwenden, zu reproduzieren, darzustellen oder in irgendeiner Form zu speichern."
        ]
      },
      {
        "title": "6. PERSÖNLICHE DATEN",
        "lines_1": [
          "Im Zusammenhang mit der Nutzung der Website und des Dienstes ist der Dienstanbieter verpflichtet, die personenbezogenen Daten des Nutzers zu verarbeiten: Die Bedingungen für die Durchführung dieser Verarbeitung sind auf der folgenden Seite beschrieben « <a href='/personal-data'> Persönliche Daten </a> » zugänglich in der Fußzeile der Website."
        ]
      },
      {
        "title": "7. PRIVACY",
        "lines_1": [
          "Im Zusammenhang mit der Nutzung des Dienstes werden die vom Nutzer an den Dienstanbieter übermittelten Informationen, einschließlich personenbezogener Daten, als vertraulich betrachtet (nachstehend die « Vertrauliche Informationen »).",
          "Dem Dienstleister ist es daher untersagt, die vom Nutzer im Rahmen der Nutzung des Dienstes mitgeteilten vertraulichen Informationen ganz oder teilweise direkt oder indirekt an Dritte, mit Ausnahme des Zahlungsempfängers, weiterzugeben und zu übermitteln.",
          "Der Dienstanbieter verpflichtet sich, technische und organisatorische Maßnahmen zu ergreifen, um die Vertraulichkeit und Sicherheit der vom Nutzer im Rahmen der Nutzung des Dienstes bereitgestellten vertraulichen Informationen zu gewährleisten.",
          "Informationen, die zum Zeitpunkt der Übermittlung an den Diensteanbieter bereits öffentlich sind, und Informationen, die auf andere Weise als durch einen Verstoß gegen die in dieser Vertraulichkeitsklausel enthaltenen Verpflichtungen öffentlich werden würden, gelten nicht als vertraulich."
        ]
      },
      {
        "title": "8. ZUGANGSBEDINGUNGEN - ANMELDUNG - VERBINDUNG ZUM DIENST",
        "subparagraphs_n1": [
          {
            "title": "8.1 Registrierung auf der Website und Zugriff auf den Dienst",
            "lines_1": [
              "Um auf den auf der Website angebotenen Dienst zugreifen zu können, muss sich der Nutzer online registrieren. Der Nutzer gibt mehrere Informationen ein, die seine Identifizierung ermöglichen (Name, Vorname, E-Mail, Telefon usw.), um den Versand eines Registrierungslinks durch den Dienst an die vom Nutzer angegebene E-Mail-Adresse zu veranlassen.",
              "In jedem Fall ist die Registrierung für den Nutzer erst dann wirksam, wenn er den vom Dienst zugesandten Registrierungslink benutzt und sein Passwort festgelegt hat.",
              "Der Nutzer kann sein Passwort nach eigenem Ermessen ändern, wobei er die vom Dienst auferlegten Regeln einhalten und sicherstellen muss, dass das Passwort nicht mit den beiden zuletzt verwendeten Passwörtern identisch ist.",
              "Die Nutzung der Website ist für den Nutzer kostenlos."
            ]
          },
          {
            "title": "8.2 Verbindung zum und Nutzung des Dienstes",
            "subparagraphs_n2": [
              {
                "title": "8.2.1 Verbinden mit dem Dienst",
                "lines_1": [
                  "Um den Dienst zu nutzen, kann sich der Nutzer direkt auf der Website anmelden."
                ]
              },
              {
                "title": "8.2.2 Nutzung des Dienstes",
                "lines_1": [
                  "Der Nutzer gibt die Identifikationsdaten und den vom Zahlungsempfänger bereitgestellten geheimen Schlüssel ein.",
                  "Handelt der Nutzer im Namen eines Schuldners, muss er die Identifikationsdaten des Schuldners und den vom Zahlungsempfänger bereitgestellten geheimen Schlüssel eingeben.",
                  "Ohne diese Elemente ist es nicht möglich, den Nutzer mit dem Schuldner zu verknüpfen und die Rechnungen des Schuldners einzusehen.",
                  "Diese Angaben sind streng vertraulich.",
                  "Der Nutzer ist allein für die Wahrung der Vertraulichkeit dieser Elemente verantwortlich. Der Nutzer verpflichtet sich, sie nicht an Dritte weiterzugeben, zu übertragen, zu verkaufen oder unterzuvermieten und den Dienstanbieter unverzüglich über jeden Verlust oder jede Unregelmäßigkeit zu informieren, die an ihnen festgestellt wird.",
                  "Der Dienstleister und der Zahlungsempfänger haften nicht für etwaige schädliche Folgen, wenn der Nutzer die Vertraulichkeit seiner vertraulichen Informationen nicht wahrt."
                ]
              }
            ]
          },
          {
            "title": "8.3 Verfügbarkeit der Website",
            "lines_1": [
              "Die Website ist in der Regel 24 Stunden am Tag, 7 Tage die Woche zugänglich, außer in Wartungszeiten. Der Dienstanbieter behält sich das Recht vor, den Zugang zur Website ohne Vorankündigung oder Entschädigung vorübergehend zu schließen, insbesondere für Wartungsarbeiten, Aktualisierungen oder andere Maßnahmen, die für das ordnungsgemäße Funktionieren der Website erforderlich sind.",
              "Der Anbieter und der Zahlungsempfänger behalten sich das Recht vor, den Inhalt und/oder die Funktionsweise der Website jederzeit entsprechend der technischen Entwicklung zu ergänzen oder zu ändern.",
              "Es liegt in der Verantwortung des Nutzers, für die Entwicklungsmöglichkeiten der ihm für den Zugang und die Nutzung der Website zur Verfügung stehenden Computer- und Übertragungsmittel Sorge zu tragen. Sollte es unmöglich sein, auf die Website zuzugreifen und/oder sie zu nutzen, kann sich der Nutzer jederzeit an den Benutzer-Support wenden, um Informationen zu erhalten."
            ]
          }
        ]
      },
      {
        "title": "9. AUFHEBUNG DES ZUGRIFFS",
        "lines_1": [
          "Der Dienstanbieter behält sich das Recht vor, den Zugang eines Nutzers zur Website und/oder zum Dienst sofort und ohne Vorankündigung zu sperren oder zu entfernen, wenn er gegen die Bestimmungen dieser Allgemeinen Geschäftsbedingungen verstößt oder wenn ein Ereignis, das den Betrieb oder die Integrität der Website und/oder des Dienstes beeinträchtigen könnte, dies erfordert."
        ]
      },
      {
        "title": "10. ZAHLUNG",
        "lines_1": [
          "Es gibt keine Mitteilung über Zahlungen oder deren Stornierung. Die einzigen Benachrichtigungen des Dienstes betreffen neue Rechnungen, die im Dienst verfügbar sind, eine Mahnung vor dem Fälligkeitsdatum und Mahnungen bei Nichtbezahlung nach dem Fälligkeitsdatum der Rechnung.",
          "Der Nutzer kann nicht haftbar gemacht werden, wenn der Dienstanbieter es versäumt, die Forderungsaufstellung zu übermitteln, vorausgesetzt, der Nutzer hat die Allgemeinen Nutzungsbedingungen eingehalten."
        ]
      },
      {
        "title": "11. NACHWEISVEREINBARUNG",
        "lines_1": [
          "Im Zusammenhang mit dem Zugang und/oder der Nutzung der Website und/oder des Dienstes werden in diesem Artikel die Regeln für die im Falle eines Rechtsstreits zwischen dem Anbieter und dem Nutzer zulässigen Beweismittel und deren Beweiskraft dargelegt. Die Bestimmungen dieses Artikels stellen somit eine Beweisvereinbarung dar, zu deren Einhaltung sich der Anbieter und der Nutzer verpflichten.",
          "Der Dienstanbieter und der Nutzer vereinbaren, dass im Falle eines Rechtsstreits die vom Nutzer zur Authentifizierung gegenüber dem Dienst verwendeten Zertifikate vor Gericht als Beweis für die hergestellten Verbindungen und das Vorhandensein von elektronisch aufgezeichneten Daten zulässig sind."
        ]
      },
      {
        "title": "12. ÄNDERUNGEN DER ALLGEMEINEN BEDINGUNGEN",
        "lines_1": [
          "Die AGB können jederzeit durch den Dienstanbieter geändert werden, insbesondere:"
        ],
        "list_1": [
          "durch die Bereitstellung neuer Funktionen oder durch die Entfernung oder Änderung bestimmter Funktionen aufgrund der sich entwickelnden Natur des Dienstes",
          "um allen rechtlichen, regulatorischen, juristischen und/oder technischen Entwicklungen Rechnung zu tragen"
        ],
        "lines_2": [
          "Die fortgesetzte Nutzung des Dienstes durch den Nutzer nach dem Datum des Inkrafttretens dieser Änderungen gilt als ausdrückliche Zustimmung des Nutzers zu den so vorgenommenen Änderungen der vorliegenden Bedingungen und Konditionen. Dem Nutzer wird daher empfohlen, vor jeder Nutzung die neueste Version der AVB zu lesen, die jederzeit auf der Website abgerufen werden kann."
        ]
      },
      {
        "title": "13. ANWENDBARES RECHT",
        "lines_1": [
          "Die vorliegenden Allgemeinen Geschäftsbedingungen unterliegen dem französischen Recht."
        ]
      },
      {
        "title": "14. STREITBEILEGUNG",
        "lines_1": [
          "Im Falle einer Streitigkeit zwischen dem Diensteanbieter und dem Nutzer und in Ermangelung einer gütlichen Einigung wird der Streitfall dem zuständigen Gericht vorgelegt."
        ]
      }
    ]
  },
  "en-GB": {
    "CGU": "GENERAL TERMS & CONDITIONS OF USE OF THE SERVICE",
    "CGU_VERSION": "Release number 1",
    "CGU_WBPM": "WL BILL PAY & MATCH",
    "PERSONAL_DATA": "Personal Data",
    "TAC_WBPM": [
      {
        "title": "1. DEFINITIONS",
        "lines_1": [
          "In the present General Terms & Conditions of Use of the <strong>Service for Electronic Invoice Presentment & Payment called « WL Bill Pay & Match (or WL BPM)</strong> » (here after the « <strong>General Conditions</strong> ») available on the website {clientWebsite} (here after the « Site »), words or expressions starting with a capital letter will have the following meaning:",
          "« <strong>Special Terms & Conditions of Use</strong> » or « <strong>ST&C</strong> »: means the special conditions of use applicable to certain Services and subject to acceptance by the User before using the Services concerned (ex. WL SPS & WL Account Based Payment for credit transfer initiated).",
          "« <strong>Creditor</strong> »: means the company using the service of provision and payment of invoices that Debtors must pay. In the case of the Site, the Creditor is {clientITName}, a simplified joint stock company with a single shareholder, whose head office is located at {clientITAdresse} and registered with the Trade and Companies Register of Nanterre under the number {clientITNum}.",
          "« <strong>Debtor</strong> »: means the customer, natural or legal person, of the Creditor and debtor of the latter. The Debtor has created a user account on the Site and uses the Service by being identified through an identifier transmitted by the Creditor.",
          "« <strong>Service provider</strong> »: means the Worldline company responsible for the development and operation of the WL Bill Pay & Match Service (WL BPM).",
          "« <strong>Service</strong> »: means WL Bill Pay & Match (WL BPM) service in its current and future version as well as any changes to which it may be subject.",
          "« <strong>Secret key</strong> »: means an element of identification of the Debtor, provided by the Creditor.",
          "« <strong>Users</strong> »: means a Debtor or any natural person acting on behalf of a Debtor (employee, accounting firm, approved management association, approved management center, holding company, etc.), who has created a User account on the Site and who uses the Service."
        ]
      },
      {
        "title": "2. T&C OBJECT AND CURRENT VERSION",
        "lines_1": [
          "The purpose of these T&C is to define the conditions under which Users can access and use the Service.",
          "Any User who accesses the Service undertakes to respect, without reservation, these T&Cs, supplemented, where applicable, by the T&C of the Services concerned. These T&Cs are notified to the User before using the Services, for express and prior acceptance.",
          "The prevailing version of the T&Cs is the one that can be accessed directly by clicking on the \"T&Cs\" link at the bottom of the Site page. The same applies to any T&Cs accessible online at the address of the Services. All Users are therefore required to refer to their version accessible online on the date of their access and use of the Services.",
          "The User is expressly informed that the only authentic version of the T&Cs of the Service is the one that can be found online on the Site, which he recognizes and accepts without restriction, committing to refer to them systematically when every connection."
        ]
      },
      {
        "title": "3. DURATION",
        "lines_1": [
          "These T&Cs are binding on the User and the Service Provider for the entire period of use of the Service."
        ]
      },
      {
        "title": "4. GUARANTEES AND RESPONSABILITY",
        "subparagraphs_n1": [
          {
            "title": "4.1 Guarantees",
            "lines_1": [
              "The Service Provider acts as a technical service provider in charge of the operation and management of the Site and the Service.",
              "In this regard, it is specified that all of the information relating to the Creditor's invoices appearing in the Service is provided directly by the Creditor who can enter, modify and update them through dedicated APIs. Consequently, the Service Provider cannot be held responsible in any way for the veracity of this information."
            ]
          },
          {
            "title": "4.2 Responsibility",
            "subparagraphs_n2": [
              {
                "title": "4.2.1 Service provider responsibility",
                "lines_1": [
                  "In the event of non-compliance with the T&Cs by the User and / or any person directly or indirectly related to him, the Service Provider and the Creditor can in no way be held liable for the damage suffered by said User or the person linked to him.",
                  "The User acknowledges that malfunctions may occur in the networks and in the operation of the Site.",
                  "The Service Provider and the Creditor cannot be held liable in the event that it is impossible to use all or part of the functionalities of the Site and / or the Service whatever the cause.",
                  "Nevertheless, The Service Provider may be held liable under the conditions of common law, for direct and foreseeable damage suffered by the User, in accordance with the provisions of the Civil Code, in particular in the event of a breach by the Service Provider of security obligations and confidentiality of confidential and / or personal data (See « <a href='/personal-data'>Personal data</a> » page at the bottom of the Site page)."
                ]
              },
              {
                "title": "4.2.2 User responsibility",
                "lines_1": [
                  "All hardware and software necessary for access and use of the Service remain the sole responsibility of the User, who is therefore solely responsible for the proper functioning of his equipment as well as his access to the Internet. It is therefore up to him to take all appropriate measures to protect his own data, computer systems and / or software from contamination by possible viruses.",
                  "The User is created an account and a password. The User is therefore responsible for the confidentiality of his account and his identifiers, as well as for all the operations that may be carried out under this account.",
                  "The User is not at any time authorized to use the account of a third party without their authorization.",
                  "In the event of breach by the User of the rules of confidentiality of the account, of use not in accordance with these T & Cs, of the password and / or of the account, the Service Provider and Creditor disclaim all liability and cannot be held liable for any damages, caused or losses suffered by the User, account holder.",
                  "The User undertakes not to allow anyone, directly or indirectly, to use this Site and Service in a way that could damage, disable, overload, deteriorate, interfere with safety, adversely affect the operation, or otherwise abuse this site or any service, system resource, account, server, network, etc.",
                  "The User undertakes to immediately notify the Service Provider in the event of any unauthorized use of his account or any other breach of security."
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "5. INTELLECTUAL PROPERTY",
        "lines_1": [
          "All the elements of the Site and the Service are protected by intellectual property rights which the Service Provider is authorized to use.",
          "These General Conditions cannot be interpreted as entailing any transfer of rights over these elements for the benefit of the User. Any reproduction or total or partial representation of the Site or the Service is liable to be sanctioned civilly and criminally for infringement.",
          "In particular, with regard to the Service, the User acknowledges that the data which compose it are protected and in particular refrains from any reuse, reproduction, representation or conservation in any form whatsoever, of all or part of the content of the databases, data that makes up the Service, for purposes other than the implementation of the Service."
        ]
      },
      {
        "title": "6. PERSONAL DATA",
        "lines_1": [
          "As part of the use of the Site and the Service, the Service Provider is required to process the User's personal data: the conditions for implementing this processing are described in the « <a href='/personal-data'>Personal data</a> » page accessible at the bottom of the Site page."
        ]
      },
      {
        "title": "7. CONFIDENTIALITY",
        "lines_1": [
          "In the context of the use of the Service, the information transmitted by the User to the Service Provider, including that comprising personal data, is considered confidential (here after \"Confidential Information\").",
          "The Service Provider is therefore prohibited from disclosing and communicating, directly or indirectly, to third parties with the exception of the Creditor, all or part of the Confidential Information communicated by the User for the purposes of using the Service.",
          "The Service Provider undertakes to implement the technical and organizational measures intended to guarantee the confidentiality and security of the Confidential Information communicated to it by the User in the context of the use of the Service.",
          "Information that is already public at the time it is transmitted to the Service Provider or that which would become public other than as a result of the violation of the commitments made in this confidentiality clause is not considered as confidential."
        ]
      },
      {
        "title": "8. ACCESS MODALITIES - REGISTRATION – SERVICE CONNECTION",
        "subparagraphs_n1": [
          {
            "title": "8.1 Registration on the website & access",
            "lines_1": [
              "To access the Service offered on the Site, the User must register online. The User enters several information allowing his identification (name, first name, email, phone ...) to generate the sending of a registration link by the Service to the email address entered by the User.",
              "In all cases, registration is effective for the User only after having used the registration link sent by the Service and defined his password.",
              "The User may change his password at his convenience, respecting the rules imposed by the Service and ensuring that the password is not identical to the last two passwords used.",
              "Use of the Site is free for the User."
            ]
          },
          {
            "title": "8.2 Usage & service connection",
            "subparagraphs_n2": [
              {
                "title": "8.2.1 Service Connection",
                "lines_1": [
                  "To use the Service, the User can connect directly to the Site."
                ]
              },
              {
                "title": "8.2.2 Service use",
                "lines_1": [
                  "The User must enter the identification information and the secret key communicated by the Creditor.",
                  "If the User is acting on behalf of a Debtor, he must enter the identification information of the Debtor and the secret key communicated by the Creditor.",
                  "Without these elements, the connection between the User and the Debtor will not be possible and the consultation of the Debtor's invoices will not be successful.",
                  "These elements are strictly confidential.",
                  "The User is solely responsible for maintaining the confidentiality of these elements. He undertakes not to communicate, assign, sell or sublet them to a third party and to inform the Service Provider without delay of any loss or anomaly noted thereof.",
                  "The Service Provider and the Creditor cannot be held responsible for any harmful consequences in the event of a failure on the part of the User to preserve the confidentiality of his confidential elements."
                ]
              }
            ]
          },
          {
            "title": "8.3 Site availability",
            "lines_1": [
              "The Site is normally accessible 24 hours a day, 7 days a week, excluding the maintenance period. The Service Provider reserves the right, without notice or compensation, to temporarily close access to the Site, in particular for the purposes of maintenance, updates or any other action deemed necessary for the proper functioning of the site.",
              "The Service Provider and the Creditor reserve the right to supplement or modify, at any time, the content and / or the functioning of the Site according to the evolution of technology.",
              "It will be up to the User to ensure the development possibilities of the IT and transmission resources available to them for accessing and using the Site. If it is impossible to access and / or use the Site, the User can always contact user support for information"
            ]
          }
        ]
      },
      {
        "title": "9. DELETION OF ACCESS",
        "lines_1": [
          "The Service Provider reserves the right to remove or make impossible, immediately and without notice, access to the Site and / or the Service to a User, in the event of non-compliance with the terms of these General Terms & Conditions or when an event liable to affect the operation or integrity of the Site and / or the Service requires it."
        ]
      },
      {
        "title": "10. PAYMENT",
        "lines_1": [
          "There is no notification about payments or their cancellation. The only notifications from the Service concern new invoices available on the Service, a reminder before due dates as well as reminders in the event of non-payment after the due date of the invoice.",
          "The User cannot be held responsible in the event of a failure by the Service Provider in the transmission of the settlement of receivables, provided that he has complied with the General Terms & Conditions of Use."
        ]
      },
      {
        "title": "11. CONVENTION OF EVIDENCES",
        "lines_1": [
          "In the context of access and / or use of the Site and / or the Service, this article sets out the rules relating to admissible evidence between the Service Provider and the User in the event of a dispute and their probative value. The provisions of this article thus constitute a proof agreement that the Service Provider and the User undertake to respect.",
          "The Service Provider and the User agree that, in the event of a dispute, the certificates used by the User to authenticate themselves to the Service are admissible in court and provide proof of the connections made and the existence of dates recorded electronically"
        ]
      },
      {
        "title": "12. GENERAL TERMS & CONDITIONS MODIFICATIONS",
        "lines_1": [
          "The T&Cs may be modified at any time by the Service Provider, in particular:"
        ],
        "list_1": [
          "by making new functionalities available, or by removing or modifying certain functionalities due to the evolving nature of the Service",
          "in order to take into account any legal, regulatory, jurisprudential and / or technical developments"
        ],
        "lines_2": [
          "The continued use of the Service by the User after the date of entry into force of such modifications will constitute express acceptance by the User of the modifications thus made herein. The User is therefore advised to refer to the latest version of the T&Cs accessible at any time on the Site before any use."
        ]
      },
      {
        "title": "13. APPLICABLE RIGHT",
        "lines_1": [
          "These General Terms & Conditions are subject to French law."
        ]
      },
      {
        "title": "14. LITIGATION",
        "lines_1": [
          "In the event of a dispute between the Service Provider and the User and, in the absence of an amicable solution, the latter will be submitted to the competent court."
        ]
      }
    ]
  },
  "fr-FR": {
    "CGU": "CONDITIONS GENERALES D’UTILISATION DU SERVICE",
    "CGU_VERSION": "Version numéro 1",
    "CGU_WBPM": "WL BILL PAY & MATCH",
    "PERSONAL_DATA": "Données personnelles",
    "TAC_WBPM": [
      {
        "title": "1. DEFINITIONS",
        "lines_1": [
          "Dans les présentes Conditions Générales d'Utilisation du <strong>Service de Consultation et de Paiement en ligne de factures nommé « WL Bill Pay & Match (WL BPM) »</strong> (ci-après les « <strong>Conditions Générales</strong> ») disponible sur le site internet {clientWebsite} (ci-après le « Site »), les mots ou expressions commençant avec une majuscule auront la signification qui suit :",
          "« <strong>Conditions Particulières d’Utilisation</strong> » ou « <strong>CPU</strong> » : désigne les conditions particulières d’utilisation applicables à certains Services et soumises à acceptation de l’Utilisateur avant l’utilisation des Services concernés (ex. WL SPS & WL Account Based Payment pour le virement initié).",
          "« <strong>Créancier</strong> » : désigne l’entreprise utilisant le service de mise à disposition et de paiement des factures que les Débiteurs doivent régler. Dans le cas du Site, le Créancier est la société {clientITName}, société par actions simplifiée à associé unique, dont le siège social se situe {clientITAdresse} et immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro {clientITNum}.",
          "« <strong>Débiteur</strong> » : désigne le client, personne physique ou morale, du Créancier et débiteur de ce dernier. Le Débiteur a créé un compte utilisateur sur le Site et utilise le Service en étant  identifié au travers d’un identifiant transmis par le Créancier.",
          "« <strong>Prestataire</strong> » : désigne la société Worldline chargée du développement et de l'exploitation du Service WL Bill Pay & Match (WL BPM).",
          "« <strong>Service</strong> » : désigne le Service WL Bill Pay & Match (WL BPM) dans sa version actuelle et future ainsi que toute évolution dont il pourrait faire l’objet.",
          "« <strong>Clé secrète</strong> » : désigne l'élément d'identification du Débiteur, fourni par le Créancier.",
          "« <strong>Utilisateur</strong> » : désigne un Débiteur ou toute personne physique agissant pour le compte d’un Débiteur (salarié, cabinet d'expertise-comptable, association de gestion agréée, centre de gestion agréé, holding…), qui a créé un compte Utilisateur sur le Site et qui utilise le Service."
        ]
      },
      {
        "title": "2. OBJET DES CGU ET VERSION EN VIGUEUR",
        "lines_1": [
          "Les présentes CGU ont pour objet de définir les conditions dans lesquelles les Utilisateurs peuvent accéder au Service et l’utiliser.",
          "Tout Utilisateur qui accède au Service s'engage à respecter, sans réserve, les présentes CGU, complétées, le cas échéant, des CPU des Services concernés. Ces CPU sont notifiées à l’Utilisateur avant utilisation des Services, pour acceptation expresse et préalable.",
          "La version des CGU qui prévaut est celle qui est accessible directement en cliquant sur le lien « CGU » en bas de page du Site. Il en est de même pour les éventuelles CPU accessibles en ligne à l’adresse des Services. Tout Utilisateur est donc tenu de se référer à leur version accessible en ligne à la date de son accès et de son utilisation des Services.",
          "L’Utilisateur est expressément informé que l’unique version des CGU du Service qui fait foi est celle qui se trouve en ligne sur le Site, ce qu’il reconnaît et accepte sans restriction, s’engageant à s’y référer systématiquement lors de chaque connexion."
        ]
      },
      {
        "title": "3. DUREE",
        "lines_1": [
          "Les présentes CGU lient l’Utilisateur et le Prestataire pour toute la durée de l’utilisation du Service."
        ]
      },
      {
        "title": "4. GARANTIES ET RESPONSABILITE",
        "subparagraphs_n1": [
          {
            "title": "4.1 Garanties",
            "lines_1": [
              "Le Prestataire intervient en tant que prestataire technique en charge de l’exploitation et de la gestion du Site et du Service.",
              "A cet égard, il est précisé que l’intégralité des informations relatives aux factures du Créancier figurant dans le Service est fournie directement par le Créancier qui peut les saisir, les modifier et les mettre à jour par le biais d’APIs dédiées. Par conséquent, le Prestataire ne saurait être tenu responsable en aucune manière de la véracité de ces informations."
            ]
          },
          {
            "title": "4.2 Responsabilité",
            "subparagraphs_n2": [
              {
                "title": "4.2.1 Responsabilité du Prestataire",
                "lines_1": [
                  "En cas de non-respect des CGU par l’Utilisateur et/ou toute personne qui lui serait liée directement ou indirectement, le Prestataire et le Créancier ne pourront en aucun cas être tenus responsables des dommages subis par ledit Utilisateur ou la personne qui lui serait liée.",
                  "L’Utilisateur reconnaît que des dysfonctionnements peuvent survenir dans les réseaux et dans le fonctionnement du Site.",
                  "La responsabilité du Prestataire et du Créancier ne peut être engagée en cas d’impossibilité d’utiliser tout ou partie des fonctionnalités du Site et/ou du Service quelle qu’en soit la cause.",
                  "Néanmoins, la responsabilité du Prestataire pourra être engagée dans les conditions de droit commun, à raison des dommages directs et prévisibles subis par l'Utilisateur, conformément aux dispositions du Code civil, notamment en cas de manquement de la part du Prestataire aux obligations de sécurité et de confidentialité des données confidentielles et/ou personnelles (Voir page « <a href='/personal-data'>Données personnelles</a> » en bas de page du Site)."
                ]
              },
              {
                "title": "4.2.2 Responsabilité de l'Utilisateur",
                "lines_1": [
                  "Tous matériels et logiciels nécessaires à l’accès et à l’utilisation du Service restent exclusivement à la charge de l’Utilisateur qui est donc seul responsable du bon fonctionnement de son équipement ainsi que de son accès à internet. Il lui appartient ainsi de prendre toutes mesures appropriées de façon à protéger ses propres données, systèmes informatiques et/ou logiciel de la contamination par d’éventuels virus.",
                  "L'Utilisateur se crée un compte et un mot de passe. L'Utilisateur est donc responsable de la confidentialité de son compte et de ses identifiants, ainsi que de toutes les opérations qui pourront être effectuées sous ce compte.",
                  "L’Utilisateur n’est à aucun moment autorisé à utiliser le compte d’une tierce personne sans l’autorisation de celle-ci.",
                  "En cas de manquement par l’Utilisateur aux règles de confidentialité du compte, d’utilisation non conforme aux présentes CGU, du mot de passe et/ou du compte, le Prestataire et le Créancier se dégagent de toute responsabilité et ne serait être tenu pour responsable des dommages causés ou des pertes subies par l’Utilisateur, titulaire du compte.",
                  "L'Utilisateur s'engage à ne pas permettre à qui que ce soit, directement ou indirectement, d'utiliser ce Site et le Service d'une façon qui pourrait endommager, désactiver, surcharger, détériorer, interférer avec la sécurité, affecter négativement le fonctionnement, ou d'abuser autrement de ce site ou de tout service, ressource système, compte, serveur, réseau, etc.",
                  "L'Utilisateur s'engage à prévenir immédiatement le Prestataire en cas de toute utilisation non autorisée de son compte ou de toute autre violation de sécurité."
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "5. PROPRIETE INTELLECTUELLE",
        "lines_1": [
          "L’intégralité des éléments du Site et du Service sont protégés par des droits de propriété intellectuelle dont le Prestataire est autorisé à faire usage.",
          "Les présentes Conditions Générales ne sauraient être interprétées comme emportant une quelconque cession de droits sur ces éléments au bénéfice de l’Utilisateur. Toute reproduction ou représentation totale ou partielle du Site ou du Service est susceptible d’être sanctionnée civilement et pénalement au titre de la contrefaçon.",
          "En particulier, s’agissant du Service, l’Utilisateur reconnait que les données qui le composent sont protégées et s’interdit notamment toute réutilisation, reproduction, représentation ou conservation sous quelque forme que ce soit, de tout ou partie du contenu des bases de données qui compose le Service, à d’autres fins que la mise en œuvre du Service."
        ]
      },
      {
        "title": "6. DONNEES A CARACTERE PERSONNEL",
        "lines_1": [
          "Dans le cadre de l’utilisation du Site et du Service, le Prestataire est amené à traiter des données à caractère personnel de l’Utilisateur : les conditions de mise en œuvre de ces traitements sont décrites au sein de la page « <a href='/personal-data'> Données personnelles </a> » accessible en bas de page du Site."
        ]
      },
      {
        "title": "7. CONFIDENTIALITE",
        "lines_1": [
          "Dans le cadre de l’utilisation du Service les informations transmises par l’Utilisateur au Prestataire, y compris celles comportant des données à caractère personnel, sont considérées comme confidentielles (ci-après les « Informations Confidentielles »).",
          "Le Prestataire s’interdit donc de divulguer et de communiquer, directement ou indirectement, aux tiers à l’exception du Créancier, tout ou partie des Informations Confidentielles communiquées par l’Utilisateur pour les besoins de l’utilisation du Service.",
          "Le Prestataire s’engage à mettre en œuvre les mesures techniques et organisationnelles destinées à garantir la confidentialité et la sécurité des Informations Confidentielles qui lui auront été communiquées par l’Utilisateur dans le cadre de l’utilisation du Service.",
          "Ne sont pas considérées comme confidentielles, les informations qui sont déjà publiques au moment où elles sont transmises au Prestataire ni celles qui viendraient à devenir publiques autrement que du fait de la violation des engagements pris dans la présente clause de confidentialité."
        ]
      },
      {
        "title": "8. MODALITES D'ACCES - INSCRIPTION - CONNEXION AU SERVICE",
        "subparagraphs_n1": [
          {
            "title": "8.1 Inscription sur le Site et accès au Service",
            "lines_1": [
              "Pour accéder au Service proposé sur le Site, l’Utilisateur doit procéder à son inscription en ligne. L’Utilisateur saisit plusieurs informations permettant son identification (nom, prénom, mail, téléphone…) pour générer l’envoi d’un lien d’inscription par le Service à l’adresse mail saisie par l’Utilisateur.",
              "Dans tous les cas l’inscription n’est effective pour l’Utilisateur qu’après avoir utilisé le lien d’inscription envoyé par le Service et défini son mot de passe.",
              "L’Utilisateur pourra modifier son mot de passe à sa convenance en respectant les règles imposées par le Service et en s’assurant que le mot de passe n’est pas identique aux deux derniers mots de passe utilisés.",
              "L’utilisation du Site est gratuite pour l’Utilisateur."
            ]
          },
          {
            "title": "8.2 Connexion au Service et utilisation",
            "subparagraphs_n2": [
              {
                "title": "8.2.1 Connexion au Service",
                "lines_1": [
                  "Pour utiliser le Service, l’Utilisateur peut se connecter directement sur le Site."
                ]
              },
              {
                "title": "8.2.2 Utilisation du Service",
                "lines_1": [
                  "L’Utilisateur devra saisir les informations d’identification et la clé secrète communiquée par le Créancier.",
                  "Si l’Utilisateur agit pour le compte d’un Débiteur, il devra saisir les informations d’identification  du Débiteur et la clé secrète communiquée par le Créancier.",
                  "Sans ces éléments, le rattachement de l’Utilisateur avec le Débiteur ne pourra pas être réalisé et la consultation des factures de du Débiteur aboutir.",
                  "Ces éléments sont strictement confidentiels.",
                  "L’Utilisateur est seul responsable de la préservation de la confidentialité de ces éléments. Il s’engage à ne pas les communiquer, céder, vendre ou sous-louer à un tiers et à informer sans délai le Prestataire de toute perte ou anomalie constatée de ceux-ci.",
                  "Le Prestataire et le Créancier ne peuvent être tenus pour responsables de toute conséquence dommageable en cas de manquement de la part de l’Utilisateur dans la préservation de la confidentialité de ses éléments confidentiels."
                ]
              }
            ]
          },
          {
            "title": "8.3 Disponibilité du Site",
            "lines_1": [
              "Le Site est normalement accessible 24h/24, 7 jours sur 7, hors période de maintenance. Le Prestataire se réserve le droit, sans préavis, ni indemnité, de fermer temporairement l’accès au Site, notamment à des fins de maintenance, de mises à jour ou de toute autre action jugée nécessaire au bon fonctionnement du site.",
              "Le Prestataire et le Créancier se réservent le droit de compléter ou de modifier, à tout moment, le contenu et/ou le fonctionnement du Site en fonction de l’évolution de la technologie.",
              "Il appartiendra à l’Utilisateur de veiller aux possibilités d’évolution des moyens informatiques et de transmission à sa disposition pour l’accès et l’utilisation du Site. En cas d’impossibilité d’accéder et/ou d’utiliser le Site, l’Utilisateur peut toujours s’adresser à l’assistance utilisateur pour obtenir des informations."
            ]
          }
        ]
      },
      {
        "title": "9. SUPPRESSION D'ACCES",
        "lines_1": [
          "Le Prestataire se réserve le droit de supprimer ou de rendre impossible, immédiatement et sans préavis, l’accès au Site et/ou au Service à un Utilisateur, en cas de non-respect des termes des présentes Conditions Générales ou lorsqu’un événement susceptible d’affecter le fonctionnement ou l’intégrité du Site et/ou du Service le nécessite."
        ]
      },
      {
        "title": "10. PAIEMENT",
        "lines_1": [
          "Il n’y a pas de notification sur les paiements ou leur annulation. Les seules notifications du Service concernent les nouvelles factures disponibles sur le Service, un rappel avant échéances ainsi que des relances en cas de non-paiement après la date d’échéance de la facture.",
          "L’Utilisateur ne saurait être tenu responsable en cas de défaillance du Prestataire dans la transmission de l’ordonnancement des créances, dès lors qu’il a respecté les Conditions Générales d’Utilisation."
        ]
      },
      {
        "title": "11. CONVENTION DE PREUVES",
        "lines_1": [
          "Dans le cadre de l’accès et/ou de l’utilisation du Site et/ou du Service le présent article fixe les règles relatives aux preuves recevables entre le Prestataire et l’Utilisateur en cas de litige et à leur force probante. Les dispositions du présent article constituent ainsi une convention de preuve que le Prestataire et l’Utilisateur s’engagent à respecter.",
          "Le Prestataire et l’Utilisateur acceptent qu’en cas de litige les certificats utilisés par l’Utilisateur pour s’authentifier au Service soient admissibles devant les tribunaux et fassent preuve des connexions réalisées et de l’existence de dates enregistrées électroniquement."
        ]
      },
      {
        "title": "12. MODIFICATIONS DES CONDITIONS GENERALES",
        "lines_1": [
          "Les CGU peuvent être modifiées à tout moment par le Prestataire, notamment :"
        ],
        "list_1": [
          "par la mise à disposition de nouvelles fonctionnalités, ou en supprimant ou en modifiant certaines fonctionnalités du fait de la nature évolutive du Service",
          "afin de prendre en compte toute évolution légale, réglementaire, jurisprudentielle et/ou technique"
        ],
        "lines_2": [
          "La poursuite de l’utilisation du Service par l’Utilisateur après la date d’entrée en vigueur de telles modifications vaudra acceptation expresse par l’Utilisateur des modifications ainsi apportées aux présentes. Il est donc conseillé à l’Utilisateur de se référer avant toute utilisation à la dernière version des CGU accessibles à tout moment sur le Site."
        ]
      },
      {
        "title": "13. DROIT APPLICABLE",
        "lines_1": [
          "Les présentes Conditions Générales sont soumises à la loi française."
        ]
      },
      {
        "title": "14. REGLEMENT DES LITIGES",
        "lines_1": [
          "En cas de litige entre le Prestataire et l’Utilisateur et, à défaut de solution amiable, celui-ci sera soumis à la juridiction compétente."
        ]
      }
    ]
  },
  "nl-NL": {
    "CGU": "ALGEMENE GEBRUIKSVOORWAARDEN VAN DE DIENST",
    "CGU_VERSION": "Versienummer 1",
    "CGU_WBPM": "WL BILL PAY & MATCH",
    "PERSONAL_DATA": "Persoonlijke gegevens",
    "TAC_WBPM": [
      {
        "title": "1. DEFINITIES",
        "lines_1": [
          "In deze Algemene Gebruiksvoorwaarden van de <strong>Online Factuurconsultatie- en Betaaldienst genaamd «WL Bill Pay & Match (WL BPM)»</strong> (hierna de «<strong>Algemene Voorwaarden</strong> ») beschikbaar op de website {clientWebsite} (hierna de 'Site'), hebben woorden of uitdrukkingen die beginnen met een hoofdletter de volgende betekenis:",
          "« <strong>Speciale gebruiksvoorwaarden</strong>” of “<strong>CPU</strong>”: duidt de bijzondere gebruiksvoorwaarden aan die van toepassing zijn op bepaalde Services en onder voorbehoud van aanvaarding door de Gebruiker voordat hij de betreffende Services gebruikt (bijv. WL SPS & WL Account Based Payment voor de geïnitieerde overboeking).",
          "« <strong>Creditor</strong>”: verwijst naar het bedrijf dat de dienst gebruikt voor het verstrekken en betalen van facturen die debiteuren moeten betalen. In het geval van de Site is de Crediteur het bedrijf {clientITName}, een vereenvoudigde naamloze vennootschap met één enkele partner, waarvan de statutaire zetel is gevestigd op {clientITAdresse} en geregistreerd bij het Handels- en Bedrijvenregister van Nanterre onder het nummer {clientITNum }. ",
          "« <strong>Schuldenaar / Debiteur</strong>”: duidt de klant, natuurlijke of rechtspersoon, van de Crediteur en debiteur van laatstgenoemde aan. De Schuldenaar heeft een gebruikersaccount op de Site aangemaakt en maakt gebruik van de Dienst door te worden geïdentificeerd aan de hand van een door de Schuldeiser doorgegeven identificatiemiddel.",
          "« <strong>Aanbieder</strong> » : duidt het Worldline-bedrijf aan dat verantwoordelijk is voor de ontwikkeling en exploitatie van de WL Bill Pay & Match Service (WL BPM).",
          "« <strong>Dienst</strong> » : verwijst naar de WL Bill Pay & Match Service (WL BPM) in zijn huidige en toekomstige versie, evenals eventuele wijzigingen waaraan deze onderhevig kan zijn.",
          "« <strong>geheime sleutel</strong> » : duidt het identificatie-element van de Schuldenaar aan, verstrekt door de Schuldeiser.",
          "« <strong>Gebruiker</strong> » : duidt een Debiteur aan of elke natuurlijke persoon die handelt namens een Debiteur (werknemer, accountantskantoor, erkende beheersvereniging, erkend managementcentrum, holdingmaatschappij, enz.), die een Gebruikersaccount op de Site heeft aangemaakt en die de Dienst gebruikt."
        ]
      },
      {
        "title": "2. DOEL VAN DE Servicevoorwaarden EN KRACHTIGE VERSIE",
        "lines_1": [
          "Het doel van deze Algemene Voorwaarden is om de voorwaarden te definiëren waaronder Gebruikers toegang kunnen krijgen tot de Dienst en deze kunnen gebruiken.",
          "Elke Gebruiker die toegang krijgt tot de Dienst verbindt zich ertoe deze Algemene Voorwaarden, indien van toepassing aangevuld met de CPU's van de betrokken Diensten, zonder voorbehoud te respecteren. Deze CPU's worden vóór het gebruik van de Diensten aan de Gebruiker meegedeeld, voor uitdrukkelijke en voorafgaande aanvaarding.",
          "De versie van de CGU die prevaleert, is degene die rechtstreeks toegankelijk is door op de link 'CGU' onderaan de pagina van de Site te klikken. Hetzelfde geldt voor alle CPU's die online toegankelijk zijn op het adres van de Diensten. Elke gebruiker is daarom verplicht om te verwijzen naar hun versie die online toegankelijk is op de datum van toegang en gebruik van de Diensten.",
          "De Gebruiker wordt er uitdrukkelijk van op de hoogte gebracht dat de enige authentieke versie van de Algemene Voorwaarden van de Dienst de versie is die online op de Site te vinden is, die hij zonder beperking herkent en aanvaardt, waarbij hij zich ertoe verbindt hier systematisch naar te verwijzen bij elke verbinding."
        ]
      },
      {
        "title": "3. DUUR",
        "lines_1": [
          "Deze Algemene Voorwaarden binden de Gebruiker en de Dienstverlener voor de gehele gebruiksduur van de Dienst."
        ]
      },
      {
        "title": "4. GARANTIES EN AANSPRAKELIJKHEID",
        "subparagraphs_n1": [
          {
            "title": "4.1 Garanties",
            "lines_1": [
              "De Dienstverlener treedt op als een technische dienstverlener die verantwoordelijk is voor de werking en het beheer van de Site en de Dienst.",
              "In dit verband wordt gespecificeerd dat alle informatie met betrekking tot de facturen van de Crediteur die in de Dienst verschijnen, rechtstreeks door de Creditor wordt verstrekt, die deze kan invoeren, wijzigen en bijwerken via speciale API's. Bijgevolg kan de Dienstverlener op geen enkele wijze verantwoordelijk worden gehouden voor de waarheidsgetrouwheid van deze informatie."
            ]
          },
          {
            "title": "4.2 Verantwoordelijkheid",
            "subparagraphs_n2": [
              {
                "title": "4.2.1 Aansprakelijkheid van de dienstverlener",
                "lines_1": [
                  "In geval van niet-naleving van de Algemene Voorwaarden door de Gebruiker en/of enige persoon die rechtstreeks of onrechtstreeks met hen verbonden is, kunnen de Dienstverlener en de Schuldeiser in geen geval aansprakelijk worden gesteld voor de schade die de Gebruiker of de met hem verbonden persoon lijdt. gekoppeld.",
                  "De Gebruiker erkent dat er storingen kunnen optreden in de netwerken en in de werking van de Site.",
                  "De Dienstverlener en de Schuldeiser kunnen niet aansprakelijk worden gesteld indien het gebruik van alle of een deel van de functionaliteiten van de Site en/of de Dienst, ongeacht de oorzaak, niet mogelijk is.",
                  "Niettemin kan de aansprakelijkheid van de Dienstverlener worden ingeroepen onder de voorwaarden van het gewoonterecht, als gevolg van directe en voorzienbare schade geleden door de Gebruiker, in overeenstemming met de bepalingen van het Burgerlijk Wetboek, in het bijzonder in geval van een tekortkoming van de kant van de Dienstverlener om te voldoen aan zijn verplichtingen inzake veiligheid en vertrouwelijkheid van vertrouwelijke en/of persoonlijke gegevens (zie pagina “<a href='/personal-data'>Persoonlijke gegevens</a>” onderaan de pagina van de Site )."
                ]
              },
              {
                "title": "4.2.2 Verantwoordelijkheid van de gebruiker",
                "lines_1": [
                  "Alle hardware en software die nodig zijn voor de toegang tot en het gebruik van de Dienst blijven uitsluitend de verantwoordelijkheid van de Gebruiker, die dus als enige verantwoordelijk is voor de goede werking van zijn apparatuur en zijn toegang tot het internet. Het is daarom zijn verantwoordelijkheid om alle passende maatregelen te nemen maatregelen om de eigen gegevens, computersystemen en/of software te beschermen tegen besmetting door mogelijke virussen.",
                  "De Gebruiker maakt een account en een wachtwoord aan. De Gebruiker is daarom verantwoordelijk voor de vertrouwelijkheid van zijn account en zijn identificatiegegevens, evenals voor alle handelingen die onder dit account kunnen worden uitgevoerd.",
                  "De Gebruiker is op geen enkel moment bevoegd om het account van een derde te gebruiken zonder toestemming van laatstgenoemde.",
                  "In geval van schending door de Gebruiker van de vertrouwelijkheidsregels van de account, gebruik niet in overeenstemming met deze Algemene Voorwaarden, van het wachtwoord en/of de account, wijzen de Dienstverlener en de Crediteur alle aansprakelijkheid af en kunnen zij niet aansprakelijk worden gesteld verantwoordelijk voor schade veroorzaakt of geleden verliezen door de Gebruiker, accounthouder.",
                  "De Gebruiker verbindt zich ertoe niemand, direct of indirect, toe te staan deze Site en de Dienst te gebruiken op een manier die deze site of enige dienst zou kunnen beschadigen, uitschakelen, overbelasten, aantasten, de veiligheid zou verstoren, de werking negatief zou kunnen beïnvloeden of anderszins zou kunnen misbruiken, systeembron, account, server, netwerk, enz.",
                  "De Gebruiker verbindt zich ertoe de Dienstverlener onmiddellijk op de hoogte te stellen in geval van ongeoorloofd gebruik van zijn account of enige andere inbreuk op de veiligheid."
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "5. INTELLECTUEEL EIGENDOM",
        "lines_1": [
          "Alle elementen van de Site en de Dienst worden beschermd door intellectuele eigendomsrechten die de Dienstverlener mag gebruiken.",
          "Deze Algemene Voorwaarden kunnen niet worden geïnterpreteerd als een overdracht van rechten op deze elementen ten behoeve van de Gebruiker. Elke reproductie of gehele of gedeeltelijke weergave van de Site of de Dienst zal waarschijnlijk civiel en strafrechtelijk worden bestraft onder namaak.",
          "In het bijzonder erkent de Gebruiker met betrekking tot de Dienst dat de gegevens waaruit deze bestaat, beschermd zijn en in het bijzonder verboden zijn voor hergebruik, reproductie, weergave of conservering in welke vorm dan ook, van de gehele of een deel van de inhoud van de databanken van gegevens waaruit de Dienst bestaat, voor andere doeleinden dan de uitvoering van de Dienst."
        ]
      },
      {
        "title": "6. PERSOONLIJKE GEGEVENS",
        "lines_1": [
          "Als onderdeel van het gebruik van de Site en de Dienst is de Dienstverlener verplicht om de persoonlijke gegevens van de Gebruiker te verwerken: de voorwaarden voor het uitvoeren van deze verwerking worden beschreven op de pagina “<a href= '/personal-data'> Persoonlijke gegevens < /a> » toegankelijk onderaan de pagina van de Site."
        ]
      },
      {
        "title": "7. VERTROUWELIJKHEID",
        "lines_1": [
          "Als onderdeel van het gebruik van de Dienst wordt de informatie die door de Gebruiker aan de Dienstverlener wordt doorgegeven, inclusief de informatie die persoonlijke gegevens bevat, als vertrouwelijk beschouwd (hierna de « Vertrouwelijke Informatie »).",
          "De Dienstverlener verbindt zich er daarom toe om de Vertrouwelijke Informatie die door de Gebruiker wordt meegedeeld met het oog op het gebruik van de Dienst, niet openbaar te maken of mee te delen, direct of indirect, aan derden, met uitzondering van de Schuldeiser.",
          "De Dienstverlener verbindt zich ertoe technische en organisatorische maatregelen te implementeren die bedoeld zijn om de vertrouwelijkheid en veiligheid te garanderen van Vertrouwelijke Informatie die hem door de Gebruiker wordt meegedeeld in het kader van het gebruik van de Dienst.",
          "Informatie die al openbaar is op het moment dat deze wordt doorgegeven aan de Dienstverlener, noch informatie die openbaar wordt anders dan als gevolg van de schending van de toezeggingen die zijn gedaan in deze vertrouwelijkheidsclausule, wordt niet als vertrouwelijk beschouwd."
        ]
      },
      {
        "title": "8. TOEGANGSVOORWAARDEN - REGISTRATIE - VERBINDING MET DE DIENST",
        "subparagraphs_n1": [
          {
            "title": "8.1 Registratie op de Site en toegang tot de Dienst",
            "lines_1": [
              "Om toegang te krijgen tot de Dienst die op de Site wordt aangeboden, moet de Gebruiker zich online registreren. De Gebruiker voert verschillende gegevens in die zijn identificatie mogelijk maken (naam, voornaam, e-mailadres, telefoonnummer, enz.) om het verzenden van een link 'registratie door' te genereren de Dienst naar het door de Gebruiker ingevoerde e-mailadres.",
              "In alle gevallen is de registratie voor de Gebruiker pas effectief nadat hij de door de Dienst verzonden registratielink heeft gebruikt en zijn wachtwoord heeft gedefinieerd.",
              "De Gebruiker kan zijn wachtwoord naar eigen inzicht wijzigen, met inachtneming van de regels opgelegd door de Dienst en ervoor zorgend dat het wachtwoord niet identiek is aan de laatste twee gebruikte wachtwoorden.",
              "Het gebruik van de site is gratis voor de gebruiker."
            ]
          },
          {
            "title": "8.2 Verbinding met de Dienst en gebruik",
            "subparagraphs_n2": [
              {
                "title": "8.2.1 Verbinding met de Dienst",
                "lines_1": [
                  "Om de Dienst te gebruiken, kan de Gebruiker rechtstreeks verbinding maken met de Site."
                ]
              },
              {
                "title": "8.2.2 Gebruik van de Dienst",
                "lines_1": [
                  "De Gebruiker moet de identificatiegegevens en de door de Schuldeiser meegedeelde geheime sleutel invoeren.",
                  "Als de Gebruiker namens een Schuldenaar handelt, moet hij de identificatiegegevens van de Schuldenaar en de door de Schuldeiser meegedeelde geheime sleutel invoeren.",
                  "Zonder deze elementen kan de link tussen Gebruiker en Debiteur niet tot stand komen en kan de raadpleging van de facturen van de Debiteur niet worden voltooid.",
                  "Deze elementen zijn strikt vertrouwelijk.",
                  "De Gebruiker is als enige verantwoordelijk voor het behoud van de vertrouwelijkheid van deze elementen. Hij verbindt zich ertoe deze niet door te geven, toe te wijzen, te verkopen of onder te verhuren aan een derde partij en de Dienstverlener onverwijld op de hoogte te stellen van enig geconstateerd verlies of enige afwijking.",
                  "De Dienstverlener en de Crediteur kunnen niet aansprakelijk worden gesteld voor eventuele schadelijke gevolgen indien de Gebruiker er niet in slaagt de vertrouwelijkheid van zijn vertrouwelijke elementen te bewaren."
                ]
              }
            ]
          },
          {
            "title": "8.3 Beschikbaarheid van de site",
            "lines_1": [
              "De Site is normaal gesproken 24 uur per dag, 7 dagen per week toegankelijk, met uitzondering van onderhoudsperioden. De Dienstverlener behoudt zich het recht voor om, zonder voorafgaande kennisgeving of compensatie, de toegang tot de Site tijdelijk te sluiten, met name voor onderhoudsdoeleinden, updates of andere actie die noodzakelijk wordt geacht voor de goede werking van de site.",
              "De Dienstverlener en de Crediteur behouden zich het recht voor om op ieder moment de inhoud en/of werking van de Site aan te vullen of te wijzigen afhankelijk van de ontwikkelingen in de technologie.",
              "Het is de taak van de Gebruiker om de evolutiemogelijkheden van de IT- en transmissiemiddelen waarover hij beschikt voor toegang tot en gebruik van de Site te verzekeren. In geval van onmogelijkheid om toegang te krijgen tot en/of gebruik te maken van de Site, kan de Gebruiker altijd contact opnemen met gebruikersondersteuning voor informatie."
            ]
          }
        ]
      },
      {
        "title": "9. TOEGANG VERWIJDEREN",
        "lines_1": [
          "De Dienstverlener behoudt zich het recht voor om de toegang tot de Site en/of de Dienst aan een Gebruiker onmiddellijk en zonder voorafgaande kennisgeving te verwijderen of onmogelijk te maken, in geval van niet-naleving van de voorwaarden van deze Algemene Voorwaarden of wanneer een gebeurtenis waarschijnlijk om de werking of integriteit van de Site en/of de Dienst dit vereist te beïnvloeden."
        ]
      },
      {
        "title": "10. BETALING",
        "lines_1": [
          "Er is geen melding over betalingen of de annulering ervan. De enige meldingen van de Dienst betreffen nieuwe facturen die beschikbaar zijn op de Dienst, een herinnering vóór de vervaldatum en herinneringen in geval van niet-betaling na de vervaldatum.",
          "De Gebruiker kan niet verantwoordelijk worden gehouden in het geval van een tekortkoming van de Dienstverlener in de overdracht van de planning van de vorderingen, op voorwaarde dat hij de Algemene Gebruiksvoorwaarden heeft nageleefd."
        ]
      },
      {
        "title": "11. BEWIJSVERDRAG",
        "lines_1": [
          "In het kader van de toegang tot en/of het gebruik van de Site en/of de Dienst stelt dit artikel de regels vast met betrekking tot het toelaatbare bewijsmateriaal tussen de Dienstverlener en de Gebruiker in geval van geschil en de bewijskracht ervan. artikel vormt aldus een bewijsovereenkomst die de Dienstverlener en de Gebruiker verbinden zich te respecteren.",
          "De Dienstverlener en de Gebruiker aanvaarden dat in geval van een geschil de certificaten die de Gebruiker gebruikt om zich bij de Dienst te authenticeren, voor de rechtbank toelaatbaar zullen zijn en het bewijs zullen leveren van de gemaakte verbindingen en het bestaan van elektronisch vastgelegde data."
        ]
      },
      {
        "title": "12. WIJZIGINGEN IN DE ALGEMENE VOORWAARDEN",
        "lines_1": [
          "De Algemene Voorwaarden kunnen op elk moment door de Dienstverlener worden gewijzigd, met name:"
        ],
        "list_1": [
          "door nieuwe functionaliteiten beschikbaar te stellen, of door bepaalde functionaliteiten te verwijderen of aan te passen vanwege het evolutionaire karakter van de Dienst",
          "om rekening te houden met eventuele juridische, regelgevende, jurisprudentiële en/of technische ontwikkelingen"
        ],
        "lines_2": [
          "Het voortgezette gebruik van de Dienst door de Gebruiker na de datum van inwerkingtreding van dergelijke wijzigingen houdt een uitdrukkelijke aanvaarding door de Gebruiker in van de aldus hierin aangebrachte wijzigingen. De Gebruiker wordt daarom geadviseerd om voor elk gebruik de nieuwste versie van de Dienst te raadplegen. Algemene voorwaarden die op elk moment op de site toegankelijk zijn."
        ]
      },
      {
        "title": "13. TOEPASSELIJK RECHT",
        "lines_1": [
          "Deze Algemene Voorwaarden zijn onderworpen aan het Franse recht."
        ]
      },
      {
        "title": "14. Regulering van geschillen",
        "lines_1": [
          "In geval van een geschil tussen de Dienstverlener en de Gebruiker en bij gebreke van een minnelijke oplossing, zal dit worden voorgelegd aan de bevoegde rechtbank."
        ]
      }
    ]
  },
  "it-IT": {
    "CGU": "TERMINI E CONDIZIONI GENERALI DI UTILIZZO DEL SERVIZIO",
    "CGU_VERSION": "Numero di rilascio 1",
    "CGU_WBPM": "WL BILL PAY & MATCH",
    "PERSONAL_DATA": "Dati personali",
    "TAC_WBPM": [
      {
        "title": "1. DEFINIZIONI",
        "lines_1": [
          "Nelle presenti Condizioni Generali di Utilizzo del <strong>Servizio di Presentazione e Pagamento della Fattura Elettronica denominato «WL Bill Pay & Match (o WL BPM)</strong>» (di seguito le « <strong>Condizioni Generali</strong> strong> ») disponibile sul sito web {clientWebsite} (di seguito il « Sito »), le parole o espressioni che iniziano con la lettera maiuscola avranno il seguente significato:",
          "« <strong>Termini e Condizioni Speciali d'Uso</strong> » o « <strong>ST&C</strong> »: indica le condizioni speciali d'uso applicabili a determinati Servizi e soggette all'accettazione da parte dell'Utente prima di utilizzare i Servizi interessati (es. (WL SPS e pagamento basato su conto WL per bonifico avviato).",
          "« <strong>Creditore</strong> »: significa la società che si avvale del servizio di fornitura e pagamento delle fatture che i Debitori sono tenuti al pagamento. Nel caso del Sito, il Creditore è {clientITName}, una società per azioni semplificata con un unico azionista, la cui sede legale si trova in {clientITAdresse} e iscritta al Registro del Commercio e delle Imprese di Nanterre con il numero {clientITNum}.",
          "« <strong>Debitore</strong> »: significa il cliente, persona fisica o giuridica, del Creditore e debitore di quest'ultimo. Il Debitore ha creato un account utente sul Sito e utilizza il Servizio essendo identificato tramite un identificatore trasmesso dal Creditore.",
          "« <strong>Fornitore di servizi</strong> »: indica la società Worldline responsabile dello sviluppo e del funzionamento del servizio WL Bill Pay & Match (WL BPM).",
          "« <strong>Servizio</strong> »: indica il servizio WL Bill Pay & Match (WL BPM) nella sua versione attuale e futura nonché le eventuali modifiche alle quali potrà essere soggetto.",
          "« <strong>Chiave segreta</strong> »: indica un elemento di identificazione del Debitore, fornito dal Creditore.",
          "« <strong>Utenti</strong> »: indica un Debitore o qualsiasi persona fisica che agisce per conto di un Debitore (dipendente, società di contabilità, associazione di gestione autorizzata, centro di gestione autorizzato, società holding, ecc.), che ha creato un account Utente sul Sito e chi utilizza il Servizio."
        ]
      },
      {
        "title": "2. OGGETTO T&C E VERSIONE ATTUALE",
        "lines_1": [
          "Lo scopo dei presenti T&C è definire le condizioni alle quali gli Utenti possono accedere e utilizzare il Servizio.",
          "Qualsiasi Utente che acceda al Servizio si impegna a rispettare, senza riserve, le presenti Condizioni Generali, integrate, ove applicabile, dalle Condizioni Generali dei Servizi interessati. Le presenti Condizioni Generali vengono notificate all'Utente prima dell'utilizzo dei Servizi, per espressa e preventiva accettazione.",
          "La versione prevalente dei T&C è quella a cui è possibile accedere direttamente cliccando sul collegamento \"T&C\" nella parte inferiore della pagina del Sito. Lo stesso vale per eventuali T&C accessibili online all'indirizzo dei Servizi. Tutti gli Utenti sono pertanto tenuti a fare riferimento alla propria versione accessibile online alla data del loro accesso e utilizzo dei Servizi.",
          "L'Utente è espressamente informato che l'unica versione autentica delle Condizioni Generali del Servizio è quella reperibile online sul Sito, che egli riconosce ed accetta senza restrizioni, impegnandosi a farvi riferimento sistematicamente ad ogni connessione."
        ]
      },
      {
        "title": "3. DURATA",
        "lines_1": [
          "I presenti T&C sono vincolanti per l'Utente e il Fornitore del Servizio per l'intero periodo di utilizzo del Servizio."
        ]
      },
      {
        "title": "4. GARANZIE E RESPONSABILITÀ",
        "subparagraphs_n1": [
          {
            "title": "4.1 Garanzie",
            "lines_1": [
              "Il Fornitore di servizi agisce in qualità di fornitore di servizi tecnici responsabile del funzionamento e della gestione del Sito e del Servizio.",
              "Al riguardo si precisa che tutte le informazioni relative alle fatture del Creditore presenti nel Servizio sono fornite direttamente dal Creditore che può inserirle, modificarle ed aggiornarle tramite API dedicate. Di conseguenza, il Fornitore del servizio non può essere ritenuto responsabile in alcun modo della veridicità di tali informazioni."
            ]
          },
          {
            "title": "4.2 Responsabilità",
            "subparagraphs_n2": [
              {
                "title": "4.2.1 Responsabilità del fornitore di servizi",
                "lines_1": [
                  "In caso di mancato rispetto delle Condizioni Generali da parte dell'Utente e/o di qualsiasi persona a lui direttamente o indirettamente collegata, il Prestatore di servizi e il Creditore non potranno in alcun modo essere ritenuti responsabili per i danni subiti da detto Utente o dalla persona a lui collegata. lui.",
                  "L'Utente riconosce che potrebbero verificarsi malfunzionamenti nelle reti e nel funzionamento del Sito.",
                  "Il Fornitore del Servizio e il Creditore non possono essere ritenuti responsabili nel caso in cui sia impossibile utilizzare tutte o parte delle funzionalità del Sito e/o del Servizio qualunque sia la causa.",
                  "Tuttavia, il Prestatore di servizi può essere ritenuto responsabile, alle condizioni del diritto comune, dei danni diretti e prevedibili subiti dall'Utente, in conformità con le disposizioni del Codice civile, in particolare in caso di violazione da parte del Prestatore di servizi della sicurezza obblighi e riservatezza dei dati riservati e/o personali (Vedi pagina « <a href='/personal-data'>Dati personali</a> » in fondo alla pagina del Sito)."
                ]
              },
              {
                "title": "4.2.2 Responsabilità dell'utente",
                "lines_1": [
                  "Tutto l'hardware e il software necessari per l'accesso e l'utilizzo del Servizio restano di esclusiva responsabilità dell'Utente, che è quindi l'unico responsabile del corretto funzionamento delle sue apparecchiature e del suo accesso a Internet. Spetta pertanto a lui adottare tutte le misure opportune per proteggere i propri dati, sistemi informatici e/o software dalla contaminazione da parte di eventuali virus.",
                  "All'Utente vengono creati un account e una password. L'Utente è quindi responsabile della riservatezza del proprio account e dei propri identificativi, nonché di tutte le operazioni che possono essere effettuate tramite tale account.",
                  "L'Utente non è in nessun caso autorizzato a utilizzare l'account di terzi senza la loro autorizzazione.",
                  "In caso di violazione da parte dell'Utente delle regole di riservatezza dell'account, di utilizzo non conforme alle presenti Condizioni Generali, della password e/o dell'account, il Prestatore e il Creditore declinano ogni responsabilità e non possono essere ritenuti responsabile per eventuali danni, causati o perdite subite dall'Utente, titolare del conto.",
                  "L'Utente si impegna a non consentire a nessuno, direttamente o indirettamente, di utilizzare questo Sito e il Servizio in un modo che possa danneggiare, disabilitare, sovraccaricare, deteriorare, interferire con la sicurezza, pregiudicare il funzionamento o altrimenti abusare di questo sito o di qualsiasi servizio, sistema risorsa, account, server, rete, ecc.",
                  "L'Utente si impegna a informare immediatamente il Fornitore di servizi in caso di utilizzo non autorizzato del proprio account o di qualsiasi altra violazione della sicurezza."
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "5. PROPRIETÀ INTELLETTUALE",
        "lines_1": [
          "Tutti gli elementi del Sito e del Servizio sono protetti dai diritti di proprietà intellettuale di cui il Fornitore del servizio è autorizzato a utilizzare.",
          "Le presenti Condizioni Generali non possono essere interpretate nel senso che comportino alcun trasferimento di diritti su tali elementi a vantaggio dell'Utente. Qualsiasi riproduzione o rappresentazione totale o parziale del Sito o del Servizio è passibile di sanzioni civili e penali per violazione.",
          "In particolare, per quanto riguarda il Servizio, l'Utente riconosce che i dati che lo compongono sono protetti e in particolare si astiene da qualsiasi riutilizzo, riproduzione, rappresentazione o conservazione, in qualsiasi forma, di tutto o parte del contenuto delle banche dati, dei dati che costituisce il Servizio, per scopi estranei all'esecuzione del Servizio."
        ]
      },
      {
        "title": "6. DATI PERSONALI",
        "lines_1": [
          "Nell'ambito dell'utilizzo del Sito e del Servizio, il Fornitore del servizio è tenuto a trattare i dati personali dell'Utente: le condizioni per l'attuazione di tale trattamento sono descritte nella sezione « <a href='/personal-data'>Dati personali</ a> » accessibile nella parte inferiore della pagina del Sito."
        ]
      },
      {
        "title": "7. RISERVATEZZA",
        "lines_1": [
          "Nell'ambito dell'utilizzo del Servizio, le informazioni trasmesse dall'Utente al Fornitore del servizio, comprese quelle che comprendono dati personali, sono considerate riservate (di seguito \"Informazioni riservate\").",
          "Al Fornitore del Servizio è pertanto vietato divulgare e comunicare, direttamente o indirettamente, a terzi, ad eccezione del Creditore, tutte o parte delle Informazioni Riservate comunicate dall'Utente ai fini della fruizione del Servizio.",
          "Il Fornitore del Servizio si impegna ad attuare le misure tecniche e organizzative intese a garantire la riservatezza e la sicurezza delle Informazioni Riservate comunicategli dall'Utente nel contesto dell'utilizzo del Servizio.",
          "Le informazioni che sono già pubbliche nel momento in cui vengono trasmesse al Fornitore di servizi o che diventerebbero pubbliche se non a seguito della violazione degli impegni assunti nella presente clausola di riservatezza non sono considerate riservate."
        ]
      },
      {
        "title": "8. MODALITÀ DI ACCESSO - REGISTRAZIONE – CONNESSIONE AL SERVIZIO",
        "subparagraphs_n1": [
          {
            "title": "8.1 Registrazione al sito e accesso",
            "lines_1": [
              "Per accedere al Servizio offerto sul Sito, l'Utente deve registrarsi online. L'Utente inserisce diverse informazioni che ne consentono l'identificazione (cognome, nome, email, telefono...) per generare l'invio di un link di registrazione da parte del Servizio all'indirizzo email inserito dall'Utente.",
              "In ogni caso, la registrazione è effettiva per l'Utente solo dopo aver utilizzato il link di registrazione inviato dal Servizio e definito la propria password.",
              "L'Utente potrà modificare la propria password a suo piacimento, rispettando le regole imposte dal Servizio e assicurandosi che la password non sia identica alle ultime due password utilizzate.",
              "L'utilizzo del Sito è gratuito per l'Utente."
            ]
          },
          {
            "title": "8.2 Utilizzo e connessione al servizio",
            "subparagraphs_n2": [
              {
                "title": "8.2.1 Connessione al servizio",
                "lines_1": [
                  "Per utilizzare il Servizio, l'Utente può collegarsi direttamente al Sito."
                ]
              },
              {
                "title": "8.2.2 Utilizzo del servizio",
                "lines_1": [
                  "L'Utente dovrà inserire i dati identificativi e la chiave segreta comunicati dal Creditore.",
                  "Se l'Utente agisce per conto di un Debitore, dovrà inserire i dati identificativi del Debitore e la chiave segreta comunicata dal Creditore.",
                  "Senza questi elementi il collegamento tra Utente e Debitore non sarà possibile e la consultazione delle fatture del Debitore non potrà andare a buon fine.",
                  "Questi elementi sono strettamente confidenziali.",
                  "L'Utente è l'unico responsabile del mantenimento della riservatezza di questi elementi. Si impegna a non comunicarli, cederli, venderli o subaffittarli a terzi e ad informare senza indugio il Prestatore di qualsiasi perdita o anomalia riscontrata.",
                  "Il Prestatore di servizi e il Creditore non possono essere ritenuti responsabili di eventuali conseguenze dannose in caso di mancata preservazione della riservatezza dei suoi elementi riservati da parte dell'Utente."
                ]
              }
            ]
          },
          {
            "title": "8.3 Disponibilità del sito",
            "lines_1": [
              "Il Sito è normalmente accessibile 24 ore su 24, 7 giorni su 7, escluso il periodo di manutenzione. Il Fornitore di servizi si riserva il diritto, senza preavviso o compenso, di chiudere temporaneamente l'accesso al Sito, in particolare a fini di manutenzione, aggiornamento o qualsiasi altra azione ritenuta necessaria per il corretto funzionamento del sito.",
              "Il Prestatore di Servizi e il Creditore si riservano il diritto di integrare o modificare, in qualsiasi momento, il contenuto e/o il funzionamento del Sito in base all'evoluzione della tecnologia.",
              "Spetterà all'Utente garantire le possibilità di sviluppo delle risorse informatiche e telematiche di cui dispone per l'accesso e l'utilizzo del Sito. Nel caso in cui sia impossibile accedere e/o utilizzare il Sito, l'Utente può sempre contattare il supporto utenti per avere informazioni"
            ]
          }
        ]
      },
      {
        "title": "9. CANCELLAZIONE DELL'ACCESSO",
        "lines_1": [
          "Il Fornitore di servizi si riserva il diritto di rimuovere o rendere impossibile, immediatamente e senza preavviso, l'accesso al Sito e/o al Servizio a un Utente, in caso di mancato rispetto dei termini delle presenti Condizioni Generali o quando si verifica un evento suscettibile di compromettere il funzionamento o l'integrità del Sito e/o del Servizio lo richiede."
        ]
      },
      {
        "title": "10. PAGAMENTO",
        "lines_1": [
          "Non vi è alcuna notifica sui pagamenti o sul loro annullamento. Le uniche notifiche da parte del Servizio riguardano le nuove fatture disponibili sul Servizio, il sollecito prima della scadenza nonché i solleciti in caso di mancato pagamento dopo la scadenza della fattura.",
          "L'Utente non può essere ritenuto responsabile in caso di mancata trasmissione della liquidazione dei crediti da parte del Prestatore di servizi, a condizione che abbia rispettato le Condizioni Generali d'Uso."
        ]
      },
      {
        "title": "11. CONVENZIONE DELLE PROVE",
        "lines_1": [
          "Nel contesto dell'accesso e/o dell'utilizzo del Sito e/o del Servizio, questo articolo stabilisce le regole relative alle prove ammissibili tra il Fornitore del servizio e l'Utente in caso di controversia e il loro valore probatorio. Le disposizioni del presente articolo costituiscono quindi un accordo prova che il Prestatore e l'Utente si impegnano a rispettare.",
          "Il Prestatore e l'Utente concordano che, in caso di controversia, i certificati utilizzati dall'Utente per autenticarsi al Servizio sono ammissibili in tribunale e forniscono la prova delle connessioni effettuate e dell'esistenza di dati registrati elettronicamente"
        ]
      },
      {
        "title": "12. MODIFICHE AI TERMINI E CONDIZIONI GENERALI",
        "lines_1": [
          "I Termini e Condizioni possono essere modificati in qualsiasi momento dal Fornitore del Servizio, in particolare:"
        ],
        "list_1": [
          "rendendo disponibili nuove funzionalità o rimuovendo o modificando determinate funzionalità a causa della natura in evoluzione del Servizio",
          "al fine di tenere conto di eventuali sviluppi giuridici, normativi, giurisprudenziali e/o tecnici"
        ],
        "lines_2": [
          "L'utilizzo continuato del Servizio da parte dell'Utente successivamente alla data di entrata in vigore di tali modifiche costituirà espressa accettazione da parte dell'Utente delle modifiche così apportate al presente documento. Si consiglia pertanto all'Utente di fare riferimento all'ultima versione dei T&C accessibile in qualsiasi momento sul Sito prima di qualsiasi utilizzo."
        ]
      },
      {
        "title": "13. DIRITTO APPLICABILE",
        "lines_1": [
          "Le presenti Condizioni Generali sono soggette alla legge francese."
        ]
      },
      {
        "title": "14. CONTENZIOSO",
        "lines_1": [
          "In caso di controversia tra il Prestatore di servizi e l'Utente e, in assenza di una soluzione amichevole, quest'ultimo sarà sottoposto al tribunale competente."
        ]
      }
    ]
  }
}
</i18n>