<template>
  <v-container>

    <h1 class="text-center">{{ $t('PERSONAL_DATA') }}</h1>
    <v-divider inset class="my-6"></v-divider>

    <i18n path="PERSONAL_DATA_DEMO_P1" tag="p">
      <template #clientWebsite>
        <a :href="clientWebsite" target="_blank">{{ clientWebsite }}</a>
      </template>
    </i18n>
    <i18n path="PERSONAL_DATA_DEMO_P2" tag="p">
      <template #clientWebsite>
        <a :href="clientWebsite" target="_blank">{{ clientWebsite }}</a>
      </template>
    </i18n>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_DEFINITIONS') }}</h2>
    <i18n path="PERSONAL_DATA_DEMO_DEFINITIONS_P1" tag="p">
      <template #creditor>
        <strong>{{ $t('PERSONAL_DATA_DEMO_CREDITOR') }}</strong>
      </template>
    </i18n>
    <i18n path="PERSONAL_DATA_DEMO_DEFINITIONS_P2" tag="p">
      <template #enterprise>
        <strong>{{ $t('PERSONAL_DATA_DEMO_ENTERPRISE') }}</strong>
      </template>
    </i18n>
    <i18n path="PERSONAL_DATA_DEMO_DEFINITIONS_P3" tag="p">
      <template #thirdParty>
        <strong>{{ $t('PERSONAL_DATA_DEMO_THIRD_PARTY') }}</strong>
      </template>
    </i18n>
    <i18n path="PERSONAL_DATA_DEMO_DEFINITIONS_P4" tag="p">
      <template #site>
        <strong>{{ $t('PERSONAL_DATA_DEMO_SITE') }}</strong>
      </template>
      <template #clientWebsite>
        <a :href="clientWebsite" target="_blank">{{ clientWebsite }}</a>
      </template>
    </i18n>
    <i18n path="PERSONAL_DATA_DEMO_DEFINITIONS_P5" tag="p">
      <template #user>
        <strong>{{ $t('PERSONAL_DATA_DEMO_USER') }}</strong>
      </template>
    </i18n>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P1') }}</p>
    <p>
      {{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P2') }}
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P3') }}</li>
        <ul>
          <li>{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P4') }}</li>
        </ul>
      </ul>
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P5') }}</li>
        <ul>
          <li>{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P6') }}</li>
          <li>{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P7') }}</li>
          <li>{{ $t('PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P8') }}</li>
        </ul>
      </ul>
    </p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P1') }}</p>
    <p>
      {{ $t('PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P2') }}
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P3') }}</li>
        <li>{{ $t('PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P4') }}</li>
      </ul>
    </p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING') }}</h2>
    <p>
      {{ $t('PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P1') }}
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P2') }}</li>
        <li>{{ $t('PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P3') }}</li>
      </ul>
    </p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_RECIPIENTS') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_RECIPIENTS_P1') }}</p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_RETENTION_PERIOD') }}</h2>
    <p>
      {{ $t('PERSONAL_DATA_DEMO_RETENTION_PERIOD_P1') }}
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_RETENTION_PERIOD_P2') }}</li>
        <li>{{ $t('PERSONAL_DATA_DEMO_RETENTION_PERIOD_P3') }}</li>
      </ul>
    </p>
    <p>{{ $t('PERSONAL_DATA_DEMO_RETENTION_PERIOD_P4') }}</p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_LOCATION') }}</h2>
    <i18n path="PERSONAL_DATA_DEMO_LOCATION_P1" tag="p">
      <template #clientWebsite>
        <a :href="clientWebsite" target="_blank">{{ clientWebsite }}</a>
      </template>
    </i18n>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_YOUR_RIGHTS') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_YOUR_RIGHTS_P1') }}</p>
    <p>
      <i18n path="PERSONAL_DATA_DEMO_YOUR_RIGHTS_P2">
        <template #clientServiceMail>
          <a :href="'mailto:'+clientServiceMail" target="_blank">{{ clientServiceMail }}</a>
        </template>
      </i18n>
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_YOUR_RIGHTS_P3') }}</li>
        <li>{{ $t('PERSONAL_DATA_DEMO_YOUR_RIGHTS_P4') }}</li>
        <li>{{ $t('PERSONAL_DATA_DEMO_YOUR_RIGHTS_P5') }}</li>
        <li>{{ $t('PERSONAL_DATA_DEMO_YOUR_RIGHTS_P6') }}</li>
      </ul>
    </p>
    <p>{{ $t('PERSONAL_DATA_DEMO_YOUR_RIGHTS_P7') }}</p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_PROTECTION') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_PROTECTION_P1') }}</p>
    <i18n path="PERSONAL_DATA_DEMO_PROTECTION_P2" tag="p">
      <template #clientWebsite>
        <a :href="clientWebsite" target="_blank">{{ clientWebsite }}</a>
      </template>
    </i18n>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_COOKIES') }}</h2>
    <h3 class="mb-2">{{ $t('PERSONAL_DATA_DEMO_COOKIES_P1') }}</h3>
    <p>{{ $t('PERSONAL_DATA_DEMO_COOKIES_P2') }}</p>
    <p>{{ $t('PERSONAL_DATA_DEMO_COOKIES_P3') }}</p>
    <p>{{ $t('PERSONAL_DATA_DEMO_COOKIES_P4') }}</p>
    <h3 class="mb-2">{{ $t('PERSONAL_DATA_DEMO_COOKIES_P5') }}</h3>
    <p>{{ $t('PERSONAL_DATA_DEMO_COOKIES_P6') }}</p>
    <h3 class="mb-2">{{ $t('PERSONAL_DATA_DEMO_COOKIES_P7') }}</h3>
    <p>{{ $t('PERSONAL_DATA_DEMO_COOKIES_P8') }}</p>
    <p>{{ $t('PERSONAL_DATA_DEMO_COOKIES_P9') }}</p>

    <v-divider inset class="my-6"></v-divider>
    <h1 class="text-center">{{ $t('PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P1') }}</h1>
    <h1 class="text-center mb-6">{{ $t('PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P2') }}</h1>
    <p>{{ $t('PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P3') }}</p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P1') }}</p>
    <p>
      {{ $t('PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P2') }}
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P3') }}</li>
      </ul>
    </p>
    <p>
      {{ $t('PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P4') }}
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P5') }}</li>
      </ul>
    </p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P1') }}</p>
    <p>
      {{ $t('PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P2') }}
      <ul>
        <li>{{ $t('PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P3') }}</li>
      </ul>
    </p>

    <h2 class="mb-4 mt-10">{{ $t('PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD') }}</h2>
    <p>{{ $t('PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD_P1') }}</p>

  </v-container>
</template>

<script>
import publicDataMixin from '@/mixins/publicDataMixin';

export default {
  mixins: [publicDataMixin]
};
</script>

<style scoped>

</style>

<i18n>
{
  "de-DE": {
    "PERSONAL_DATA": "Persönliche Daten",
    "PERSONAL_DATA_DEMO_CREDITOR": "Zahlungsempfänger",
    "PERSONAL_DATA_DEMO_ENTERPRISE": "Unternehmen",
    "PERSONAL_DATA_DEMO_SITE": "Standort",
    "PERSONAL_DATA_DEMO_THIRD_PARTY": "Drittpartei",
    "PERSONAL_DATA_DEMO_USER": "Benutzer - Endnutzer",
    "PERSONAL_DATA_DEMO_P1": "Während des Besuchs der Website {bpmWebsite} und der Nutzung der WL Bill Pay & Match-Services ist Worldline (für den Betrieb des Services verantwortliche Stelle) verpflichtet, die Sie betreffenden personenbezogenen Daten als Subunternehmer im Auftrag von Demo zu erfassen und zu verwenden. Worldline verpflichtet sich, personenbezogene Daten unter strikter Einhaltung der allgemeinen europäischen Datenschutzverordnung (Verordnung (EU) 2016/679 vom 27. April 2016) und des Gesetzes Nr. 78-17 vom 6. Januar 1978 in seiner geänderten Fassung über Informatik, Dateien und Freiheiten zu verarbeiten.",
    "PERSONAL_DATA_DEMO_P2": "Diese Seite informiert Sie darüber, wie Ihre persönlichen Daten auf der Website {bpmWebsite} geschützt und verarbeitet werden, und sie ist vollständiger Bestandteil der Worldline Bill Pay & Match Terms & Conditions.",
    "PERSONAL_DATA_DEMO_DEFINITIONS": "1. Definitionen",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P1": "« {creditor} »: bezeichnet das Unternehmen, das die Dienstleistung der Bereitstellung und Bezahlung von Rechnungen in Anspruch nimmt, die die Schuldner (Kundenunternehmen) bezahlen müssen.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P2": "« {enterprise} »: bezeichnet ein Kundenunternehmen des Zahlungsempfängers. Ein Unternehmen wird durch den erforderlichen Identifikator identifiziert und vom Zahlungsempfänger angefordert.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P3": "« {thirdParty} »: bezeichnet die für die Entwicklung und den Betrieb der WL Bill Pay & Match (WL BPM) Services verantwortliche Stelle; in diesem Fall ist es Worldline.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P4": "« {site} »:  bezeichnet die Website, deren Inhalt über die folgende URL-Adresse zugänglich ist {bpmWebsite}.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P5": "« {user} »: bezieht sich auf jede natürliche Person, die im Namen eines Unternehmens oder eines Drittzahlers handelt, ein Benutzerkonto auf der Website erstellt hat und den Dienst nutzt.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING": "2. Zwecke der verarbeitung personenbezogener daten",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P1": "Personenbezogene Daten werden für bestimmte, eindeutige und rechtmäßige Zwecke verarbeitet.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P2": "Sie sind dazu bestimmt, Folgendes zu ermöglichen:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P3": "Ihr Unternehmen:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P4": "Einreichung und Zahlung der Rechnung(en) über unser Portal",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P5": "Zahlungsempfänger:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P6": "Verwalten der Rechnungen und Gutschriften",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P7": "Geleistete Zahlungen verfolgen",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P8": "E-Mail- und SMS-Benachrichtigungen",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED": "3. die Art der erhobenen personenbezogenen Daten",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P1": "Worldline gewährleistet das Prinzip der Minimierung der erhobenen Daten. Folglich sind die erhobenen personenbezogenen Daten angemessen, relevant und streng auf das beschränkt, was im Hinblick auf die Zwecke, für die sie verarbeitet werden, erforderlich ist.",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P2": "Es werden folgende Kategorien personenbezogener Daten erhoben:",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P3": "Identitäts- und Kontaktdaten des Nutzers des WL Bill Pay & Match Service,",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P4": "Bankkarten in einer PCI DSS-konformen Umgebung (wenn sie identifizierbare juristische Personen betreffen und direkt oder indirekt auf eine natürliche Person zurückgeführt werden können)",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING": "4. rechtliche Grundlagen für die Verarbeitung personenbezogener Daten",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P1": "Die Verarbeitung personenbezogener Daten durch Worldline hat die folgende Rechtsgrundlage:",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P2": "Die Erfüllung des Vertrages zwischen Ihrem Unternehmen oder dem Unternehmen, in dessen Auftrag Sie arbeiten, und dem Gläubiger.",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P3": "Erfüllung einer gesetzlichen Verpflichtung für den Gläubiger",
    "PERSONAL_DATA_DEMO_RECIPIENTS": "5. die Empfänger der personenbezogenen Daten",
    "PERSONAL_DATA_DEMO_RECIPIENTS_P1": "Außer Worldline als Unterauftragnehmer, der einen Datenzugang hat, können diese Daten auch an den Zahlungsempfänger übermittelt werden.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD": "6. Aufbewahrungsfrist und Löschung der erhobenen personenbezogenen Daten",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P1": "Ihre personenbezogenen Daten werden für einen Zeitraum von 12 Monaten nach Beendigung des Vertragsverhältnisses zwischen Ihrem Unternehmen oder dem Unternehmen, in dessen Auftrag Sie arbeiten, und dem Kreditgeber aktiv aufbewahrt, um:",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P2": "einerseits dem Gläubiger zu ermöglichen, die Historie der in seinem Namen geleisteten Zahlungen zu verfolgen;",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P3": "einerseits, um den Gläubiger in die Lage zu versetzen, die Historie der in seinem Namen getätigten Zahlungen nachzuvollziehen;",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P4": "Sie werden dann nach Ablauf der Aufbewahrungsfrist automatisch gelöscht.",
    "PERSONAL_DATA_DEMO_LOCATION": "7. der Standort Ihrer persönlichen Daten",
    "PERSONAL_DATA_DEMO_LOCATION_P1": "Personenbezogene Daten, die auf der Website {bpmWebsite} verarbeitet werden, werden in der Zone Europa gespeichert.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS": "8. Ihre Rechte in Bezug auf personenbezogene Daten",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P1": "Gemäß den Bestimmungen der Allgemeinen Datenschutzverordnung (DSGVO) und dem geänderten Datenschutzgesetz haben Sie das Recht auf Zugang, Widerspruch aus berechtigten Gründen, Berichtigung und Löschung aller Sie betreffenden personenbezogenen Daten. Sie haben auch das Recht, die Verarbeitung und die Übertragbarkeit der Sie betreffenden personenbezogenen Daten auf ein Drittunternehmen einzuschränken, sowie das Recht, Richtlinien über das Schicksal der Sie betreffenden personenbezogenen Daten nach Ihrem Tod festzulegen.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P2": "Um Ihre Rechte auszuüben, können Sie uns unter Nachweis Ihrer Identität (Kopie eines gültigen Ausweises) per E-Mail an die Adresse {bpmSupportMail} oder per Brief kontaktieren:",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P3": "Worldline",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P4": "A l'attention du Service WL Bill Pay & Match",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P5": "Fluss Ouest, 80 quai Voltaire",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P6": "F-95870 Frankreich - Bezons",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P7": "Schließlich haben Sie das Recht, sich im Falle eines Verstoßes gegen die geltenden Vorschriften zum Schutz personenbezogener Daten, insbesondere gegen die DSGVO, an die französische Nationale Kommission für Informatik und Freiheiten (CNIL) zu wenden. Wir laden Sie ein, uns vor jeder Inanspruchnahme zu kontaktieren, damit wir alles tun können, um ein mögliches Problem zu lösen.",
    "PERSONAL_DATA_DEMO_PROTECTION": "9. der Schutz Ihrer persönlichen Daten",
    "PERSONAL_DATA_DEMO_PROTECTION_P1": "Worldline verpflichtet sich, geeignete technische und organisatorische Maßnahmen zu ergreifen, um die Sicherheit und Vertraulichkeit Ihrer personenbezogenen Daten zu gewährleisten und diese insbesondere vor Zugriff, Manipulation, Veränderung, Verlust oder gar Zerstörung durch unbefugte Dritte zu schützen.",
    "PERSONAL_DATA_DEMO_PROTECTION_P2": "Das gesicherte HTTPS-Protokoll wird für den gesamten Austausch zwischen Ihrem Browser und der Website {bpmWebsite} verwendet.",
    "PERSONAL_DATA_DEMO_COOKIES": "10. Verwendung von Cookies",
    "PERSONAL_DATA_DEMO_COOKIES_P1": "10.1 Was ist ein Cookie?",
    "PERSONAL_DATA_DEMO_COOKIES_P2": "Ein Cookie ist eine kleine Datei mit Buchstaben und Zahlen, die auf Ihren Computer heruntergeladen wird, wenn Sie auf bestimmte Websites zugreifen.",
    "PERSONAL_DATA_DEMO_COOKIES_P3": "Der Zweck eines Cookies ist es, wenn er von einem Browser gelesen wird, einer Website zu helfen, den angebotenen Service zu verbessern.",
    "PERSONAL_DATA_DEMO_COOKIES_P4": "Das Cookie enthält mehrere Daten: den Namen des Servers, der es hinterlegt hat, seine Lebensdauer und eine Kennung in Form einer eindeutigen Nummer.",
    "PERSONAL_DATA_DEMO_COOKIES_P5": "10.2 Wer kann Cookies in Ihrem Browser installieren und zu welchem Zweck?",
    "PERSONAL_DATA_DEMO_COOKIES_P6": "Worldline kann Cookies auf Ihrem Browser installieren, um sicherzustellen, dass die Website optimal funktioniert.",
    "PERSONAL_DATA_DEMO_COOKIES_P7": "10.3 Welche Arten von Cookies werden auf der Website gesetzt und wie lange sind sie gültig?",
    "PERSONAL_DATA_DEMO_COOKIES_P8": "Wenn Sie auf unserer Website surfen, kann es sein, dass wir eine bestimmte Art von Cookies auf Ihrem Computer ablegen müssen: Cookies, die für das reibungslose Funktionieren der Website unbedingt erforderlich sind. Diese Cookies sind für das Surfen auf unserer Website unerlässlich, da sie zu ihrem Schutz beitragen und die Nutzung ihrer wichtigsten Funktionen ermöglichen.",
    "PERSONAL_DATA_DEMO_COOKIES_P9": "Diese Cookies werden automatisch gelöscht, wenn sich der Benutzer abmeldet oder die Seite geschlossen wird.",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P1": "Ergänzung für den Dienst Worldline Sepa Payment Suite (WL SPS)",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P2": "(SEPA-Lastschrifterstellung und Unterschriftsfeld)",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P3": "Dieses Kapitel gilt nur für die WL SPS Lösung für SEPA Direct Debit. Es ergänzt die obigen Bestimmungen über die Art und Weise der Verarbeitung Ihrer personenbezogenen Daten speziell für den WL SPS-Service und ist Bestandteil der Besonderen Nutzungsbedingungen des WL SPS-Service.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING": "1. Zwecke der Verarbeitung personenbezogener Daten",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P1": "Personenbezogene Daten werden für bestimmte, eindeutige und rechtmäßige Zwecke verarbeitet.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P2": "Sie sind dazu bestimmt, Ihrem Unternehmen zu ermöglichen",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P3": "Zahlung von Rechnungen mit WL SPS",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P4": "Und dem Zahlungsempfänger zu ermöglichen:",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P5": "Stellen Sie Ihre Authentifizierung im Rahmen des elektronischen Signaturprozesses sicher.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED": "2. die Art der erhobenen personenbezogenen Daten",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P1": "Die erhobenen personenbezogenen Daten sind für den Zweck der Erhebung unbedingt erforderlich. Worldline gewährleistet den Grundsatz der Minimierung der erhobenen Daten.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P2": "Worldline erhebt nur die folgenden Datenkategorien, die zur Erreichung der Zwecke der Verarbeitung unbedingt erforderlich sind:",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P3": "Identität, Kontaktangaben",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD": "3. die Aufbewahrungsfrist und Löschung der erhobenen personenbezogenen Daten",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD_P1": "Ihre personenbezogenen Daten werden in einer aktiven Datenbank für die Dauer aufbewahrt, die für die Ausführung der elektronisch unterzeichneten Mandate erforderlich ist, verlängert um die Verjährungsfrist von 5 Jahren. Nach Ablauf der Aufbewahrungsfrist werden sie automatisch gelöscht."
  },
  "en-GB": {
    "PERSONAL_DATA": "Personal data",
    "PERSONAL_DATA_DEMO_CREDITOR": "Creditor",
    "PERSONAL_DATA_DEMO_ENTERPRISE": "Enterprise",
    "PERSONAL_DATA_DEMO_SITE": "Site",
    "PERSONAL_DATA_DEMO_THIRD_PARTY": "Third party",
    "PERSONAL_DATA_DEMO_USER": "User - End User",
    "PERSONAL_DATA_DEMO_P1": "While browsing the website {clientWebsite} and use WL Bill Pay & Match services, Worldline (entity in charge of operating the service) is required to collect and use personal data concerning you as a subcontractor on behalf of Demo. Worldline undertakes to process personal data in strict compliance with the general European data protection regulation (EU regulation 2016/679 of April 27, 2016) and of law n ° 78-17 of January 6, 1978 as amended relating to the IT, files and freedoms.",
    "PERSONAL_DATA_DEMO_P2": "This page informs you of how your personal data is protected and processed on the website {clientWebsite} and it is full part of Worldline Bill Pay & Match Terms & Conditions.",
    "PERSONAL_DATA_DEMO_DEFINITIONS": "1. Definitions",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P1": "« {creditor} »: means the company using the service of provision and payment of invoices that Debtors (Client Companies) must pay.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P2": "« {enterprise} »: means a client company to the Creditor. A company is identified through the required identifier and requested by the Creditor.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P3": "« {thirdParty} »: means the entity responsible for the development and operation of the WL Bill Pay & Match (WL BPM) services; in this case it is Worldline.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P4": "« {site} »: means the website whose content is accessible from this following url address {clientWebsite}.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P5": "« {user} »: refers to any natural person acting on behalf of a Company or a Third Party Payer who has created a User account on the website and who uses the Service.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING": "2. Purposes of personal data processing",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P1": "Personal data is processed for specific, explicit and legitimate purposes.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P2": "They are intended to allow:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P3": "Your enterprise:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P4": "Presentment and invoice(s) payment(s) through our portal",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P5": "Creditor:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P6": "Manage the invoices and credit note primers",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P7": "Track payments made",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P8": "Email and text message notifications",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED": "3. Nature of personal data collected",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P1": "Worldline ensures the principle of minimizing the data collected. Consequently, the personal data collected is adequate, relevant and strictly limited to what is necessary with regard to the purposes for which they are processed.",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P2": "The categories of personal data collected are as following:",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P3": "Identity and contact details of the WL Bill Pay & Match service user,",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P4": "Bank cards on a compliant PCI DSS environment (when they concern identifiable legal persons and reducible, directly or indirectly, to a natural person)",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING": "4. Legal bases for the processing of personal data",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P1": "The processing of personal data by Worldline has the following legal basis:",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P2": "The performance of the contract between your company or the company on whose behalf you work and the creditor.",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P3": "Compliance with a legal obligation for the creditor.",
    "PERSONAL_DATA_DEMO_RECIPIENTS": "5. Recipients of personal data",
    "PERSONAL_DATA_DEMO_RECIPIENTS_P1": "Except Worldline as a subcontractor which has a data access, this data can also be transmitted to Creditor.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD": "6. Retention period and erasure of personal data collected",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P1": "Your personal data is kept on an active basis for a period of 12 months from the end of the contractual relationship between your company or the company on whose behalf you work and the creditor, in order to:",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P2": "on the one hand, to allow the creditor to follow the history of payments made on their behalf;",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P3": "on the other hand, to meet legal obligations in the fight against money laundering and the financing of terrorism and the fight against fraud.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P4": "They are then automatically deleted at the end of their storage period.",
    "PERSONAL_DATA_DEMO_LOCATION": "7. Location of your personal data",
    "PERSONAL_DATA_DEMO_LOCATION_P1": "Personal data processed on the website {clientWebsite} are stored in Europe zone.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS": "8. Your rights on personal data",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P1": "In accordance with the provisions of the General Data Protection Regulation (GDPR) and the amended Data Protection Act, you have the right to access, oppose for legitimate reasons, rectification and erasure on all personal data concerning you. You also have a right to limit the processing and portability of personal data concerning you to a third party company, as well as a right to define guidelines relating to the fate of personal data concerning you after your death.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P2": "To exercise your rights, you can contact us, proving your identity (copy of your valid identity document), by email at the email address {clientServiceMail} or by letter:",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P3": "Worldline",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P4": "A l’attention du Service WL Bill Pay & Match",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P5": "River Ouest, 80 quai Voltaire",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P6": "F-95870 France - Bezons",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P7": "Finally, you have the right to appeal to the French National Commission for Informatics and Freedoms (CNIL) in the event of a violation of the applicable regulations on the protection of personal data and in particular the GDPR. We invite you to contact us before any recourse, so that we do everything possible to resolve any possible problem.",
    "PERSONAL_DATA_DEMO_PROTECTION": "9. Protection against your personal data",
    "PERSONAL_DATA_DEMO_PROTECTION_P1": "Worldline undertakes to implement the appropriate technical and organizational measures in order to guarantee the security and confidentiality of your personal data and in particular to protect them against access, manipulation, alteration, loss or even destruction by unauthorized third parties.",
    "PERSONAL_DATA_DEMO_PROTECTION_P2": "The secured HTTPS protocol is used for all exchanges between your browser and the website {clientWebsite}.",
    "PERSONAL_DATA_DEMO_COOKIES": "10. Use of cookies",
    "PERSONAL_DATA_DEMO_COOKIES_P1": "10.1 What’s a cookies?",
    "PERSONAL_DATA_DEMO_COOKIES_P2": "A cookie is a small file of letters and numbers downloaded to your computer when you access certain websites.",
    "PERSONAL_DATA_DEMO_COOKIES_P3": "The purpose of a cookie is, when read by a browser, to help a website improve the service provided.",
    "PERSONAL_DATA_DEMO_COOKIES_P4": "The cookie contains several data: the name of the server which deposited it, its lifespan and an identifier in the form of a unique number.",
    "PERSONAL_DATA_DEMO_COOKIES_P5": "10.2 Who can install cookies on your browser, and for what purpose?",
    "PERSONAL_DATA_DEMO_COOKIES_P6": "Worldline may install cookies on your browser in order to ensure that the website functions optimally.",
    "PERSONAL_DATA_DEMO_COOKIES_P7": "10.3 What types of cookies are placed on the site and their lifespan?",
    "PERSONAL_DATA_DEMO_COOKIES_P8": "When you browse our site, we may have to place a certain type of cookies on your computer: cookies strictly necessary for the proper functioning of the site. These cookies are essential for browsing our site because they help protect it and allow you to use its main features.",
    "PERSONAL_DATA_DEMO_COOKIES_P9": "These cookies are automatically deleted when the user logs off or the page is closed.",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P1": "Supplement applicable to Worldline Sepa Payment Suite (WL SPS) service",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P2": "(SEPA Direct Debit creation and sign space)",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P3": "This chapter only applies for WL SPS solution for SEPA Direct Debit. It complements the above provisions regarding the methods of processing your personal data specific to the WL SPS service and is an integral part of the Special Conditions of Use of the WL SPS service.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING": "1. Purposes of personal data processing",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P1": "Personal data is processed for specific, explicit and legitimate purposes.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P2": "They are intended to allow your Entreprise",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P3": "Payment of invoices with WL SPS",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P4": "And to allow the Creditor:",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P5": "Ensure your authentication as part of the electronic signature process.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED": "2. Nature of personal data collected",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P1": "The personal data collected is strictly necessary for the purpose of the collection. Worldline ensures the principle of minimizing the data collected.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P2": "Worldline only collects the following categories of data that are strictly necessary to achieve the purposes of the processing:",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P3": "Identity, contact details",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD": "3. Retention period and erasure of personal data collected",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD_P1": "Your personal data is kept in an active database for the duration necessary for the execution of mandates signed electronically, extended by the limitation period of 5 years. They are then automatically deleted at the end of their storage period."
  },
  "fr-FR": {
    "PERSONAL_DATA": "Données personnelles",
    "PERSONAL_DATA_DEMO_CREDITOR": "Créancier",
    "PERSONAL_DATA_DEMO_ENTERPRISE": "Entreprise",
    "PERSONAL_DATA_DEMO_SITE": "Site",
    "PERSONAL_DATA_DEMO_THIRD_PARTY": "Prestataire",
    "PERSONAL_DATA_DEMO_USER": "Utilisateur",
    "PERSONAL_DATA_DEMO_P1": "Lors de votre navigation sur le site {clientWebsite} et utilisation du service WL Bill Pay & Match, Worldline (entité en charge de l’exploitation du Service) est amené à collecter et utiliser des données personnelles vous concernant en sa qualité de sous-traitant pour le compte de Demo. Worldline s’engage à traiter les données personnelles dans le strict respect du règlement général européen sur la protection des données (règlement UE 2016/679 du 27 avril 2016) et de la loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés.",
    "PERSONAL_DATA_DEMO_P2": "La présente page vous informe de la manière dont vos données personnelles sont protégées et traitées sur le site {clientWebsite} et fait partie intégrante des Conditions Générales d’Utilisation du service WL Bill Pay & Match.",
    "PERSONAL_DATA_DEMO_DEFINITIONS": "1. Définitions",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P1": "« {creditor} » désigne l’entreprise utilisant le service de mise à disposition et de paiement des factures que les Débiteurs (Entreprises clientes) doivent régler.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P2": "« {enterprise} » : désigne une entreprise cliente auprès du Créancier. Une entreprise est identifiée au travers de l’identifiant requis et demande par le Créancier.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P3": "« {thirdParty} » : désigne l’entité chargée du développement et de l'exploitation du Service WL Bill Pay & Match (WL BPM) en l’occurrence Worldline.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P4": "« {site} » : désigne le site internet dont le contenu est accessible à partir de l’adresse url {clientWebsite}.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P5": "« {user} » : désigne toute personne physique agissant pour le compte d’une Entreprise ou d’un Tiers-Payeur qui a créé un compte Utilisateur sur le Site et qui utilise le Service.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING": "2. Finalités des traitements de données personnelles",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P1": "Les données personnelles sont traitées pour des finalités déterminées, explicites et légitimes.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P2": "Elles sont destinées à permettre à :",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P3": "Votre entreprise de :",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P4": "Réaliser le paiement de ses factures",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P5": "Au créancier de :",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P6": "Gérer les amorces",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P7": "Suivre les paiements réalisés",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P8": "Effectuer des notifications e-mail / SMS",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED": "3. Nature des données personnelles collectées",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P1": "Worldline veille au principe de minimisation des données collectées. En conséquence, les données personnelles collectées sont adéquates, pertinentes et strictement limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées.",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P2": "Les catégories de données personnelles collectées sont les suivantes :",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P3": "Identité et coordonnées de l’utilisateur du service WL Bill Pay & Match,",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P4": "Données bancaires (lorsqu’elles concernent des personnes morales identifiables et réductibles, directement ou indirectement, à une personne physique)",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING": "4. Fondements juridiques des traitements de données personnelles",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P1": "Les traitements de vos données par Worldline ont pour fondement juridique :",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P2": "L’exécution du contrat qui lie votre entreprise ou l’entreprise pour le compte de laquelle vous travaillez et le créancier.",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P3": "Le respect d’une obligation légale pour le créancier.",
    "PERSONAL_DATA_DEMO_RECIPIENTS": "5. Destinataires des données personnelles",
    "PERSONAL_DATA_DEMO_RECIPIENTS_P1": "Outre le sous-traitant Wordline qui a accès aux données, celles-ci sont également transmises au créancier.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD": "6. Durée de conservation et effacement des données personnelles collectées",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P1": "Vos données personnelles sont conservées en base active pendant une durée de 12 mois à compter de la fin de la relation contractuelle entre votre entreprise ou l’entreprise pour le compte de laquelle vous travaillez et le créancier, afin :",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P2": "d’une part de permettre au créancier de suivre l’historique des paiements réalisés pour leur compte ;",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P3": "d’autre part de répondre aux obligations légales de lutte contre le blanchiment d’argent et le financement du terrorisme et de lutte contre la fraude.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P4": "Elles sont ensuite automatiquement effacées à la fin de leur durée de conservation.",
    "PERSONAL_DATA_DEMO_LOCATION": "7. Localisation de vos données personnelles",
    "PERSONAL_DATA_DEMO_LOCATION_P1": "Les données personnelles traitées sur le site {clientWebsite} sont conservées au sein de l’Union Européenne.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS": "8. Vos droits sur vos données personnelles",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P1": "Conformément aux dispositions du règlement général sur la protection des données (RGPD) et de la loi informatique et libertés modifiée, vous disposez d’un droit d’accès, d’opposition pour motif légitime, de rectification et d’effacement sur la totalité des données personnelles vous concernant. Vous disposez également d’un droit de limitation du traitement et de la portabilité des données personnelles vous concernant à une société tierce, ainsi que d’un droit de définir des directives relatives au sort des données personnelles vous concernant après votre mort.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P2": "Pour exercer vos droits, vous pouvez nous contacter, en justifiant de votre identité (copie de votre pièce d’identité en cours de validité), par courrier électronique à l’adresse {clientServiceMail} ou par lettre recommandée avec avis de réception à l’adresse suivante :",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P3": "Worldline",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P4": "A l’attention du Service WL Bill Pay & Match",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P5": "River Ouest, 80 quai Voltaire",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P6": "95870 Bezons",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P7": "Enfin, vous disposez du droit de recours auprès de la Commission nationale de l'informatique et des libertés (CNIL) en cas de violation de la règlementation applicable en matière de protection des données personnelles et notamment du RGPD. Nous vous invitons à prendre contact avec nous avant tout recours, pour que nous mettions tout en œuvre afin de résoudre tout éventuel problème.",
    "PERSONAL_DATA_DEMO_PROTECTION": "9. Protection de vos données personnelles",
    "PERSONAL_DATA_DEMO_PROTECTION_P1": "Worldline s’engage à mettre en œuvre les mesures techniques et organisationnelles appropriées afin de garantir la sécurité et la confidentialité de vos données personnelles et notamment les protéger contre l’accès, la manipulation, l’altération, la perte ou encore la destruction par des tiers non autorisés.",
    "PERSONAL_DATA_DEMO_PROTECTION_P2": "Le protocole sécurisé HTTPS est utilisé pour tous les échanges entre votre navigateur et le site {clientWebsite}.",
    "PERSONAL_DATA_DEMO_COOKIES": "10. Utilisation des cookies",
    "PERSONAL_DATA_DEMO_COOKIES_P1": "10.1 Qu’est-ce qu’un cookie ?",
    "PERSONAL_DATA_DEMO_COOKIES_P2": "Un cookie consiste en un petit fichier de lettres et de chiffres téléchargé sur votre ordinateur lorsque vous accédez à certains sites web.",
    "PERSONAL_DATA_DEMO_COOKIES_P3": "Le but d’un cookie est, lorsqu’il est lu par un navigateur, d’aider un site web à améliorer le service fourni.",
    "PERSONAL_DATA_DEMO_COOKIES_P4": "Le cookie contient plusieurs données : le nom du serveur qui l’a déposé, sa durée de vie et un identifiant sous forme de numéro unique.",
    "PERSONAL_DATA_DEMO_COOKIES_P5": "10.2 Qui peut installer des cookies sur votre navigateur, et dans quel but ?",
    "PERSONAL_DATA_DEMO_COOKIES_P6": "Worldline peut installer des cookies sur votre navigateur dans l’objectif de garantir un fonctionnement optimal du site web.",
    "PERSONAL_DATA_DEMO_COOKIES_P7": "10.3 Quels sont les types de cookies mis en place sur le site et leur durée de vie ?",
    "PERSONAL_DATA_DEMO_COOKIES_P8": "Lorsque vous naviguez sur notre site, nous pouvons être amenés à déposer un certain type de cookies sur votre terminal : les cookies strictement nécessaires au bon fonctionnement du site. Ces cookies sont indispensables à la navigation sur notre site car ils participent à sa protection et vous permettent d’utiliser ses principales fonctionnalités.",
    "PERSONAL_DATA_DEMO_COOKIES_P9": "Ces cookies sont automatiquement supprimés à la fermeture de la session ou à la fermeture de la page par l'utilisateur.",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P1": "Complément applicable au service Worldline SPS",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P2": "(Espace de création et de signature de votre prélèvement SEPA)",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P3": "Le présent complément s’applique au service SPS. Il complète les dispositions qui précèdent s’agissant de modalités de traitement de vos données personnelles spécifiques au service SPS et fait partie intégrante Conditions Particulières d’Utilisation du service SPS.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING": "1. Finalités des traitements de données personnelles",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P1": "Les données personnelles sont traitées pour des finalités déterminées, explicites et légitimes.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P2": "Elles sont destinées à permettre à votre entreprise :",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P3": "Le paiement de ses factures",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P4": "Et à permettre au Créancier :",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P5": "D’assurer votre authentification dans le cadre du procédé de la signature électronique.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED": "2. Nature des données personnelles collectées",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P1": "Les données personnelles collectées sont strictement nécessaires à l’objectif poursuivi par la collecte. Worldline veille au principe de minimisation des données collectées.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P2": "Worldline ne collecte que les catégories de données suivantes strictement nécessaires à l’atteinte des finalités du traitement :",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P3": "Identité, coordonnées",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD": "3. Durée de conservation et effacement des données personnelles collectées",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD_P1": "Vos données personnelles sont conservées en base active pendant toute la durée nécessaire à l’exécution des mandats signés électroniquement, allongée de la durée de prescription de 5 ans. Elles sont ensuite automatiquement effacées à la fin de leur durée de conservation."
  },
  "nl-NL": {
    "PERSONAL_DATA": "Persoonlijke gegevens",
    "PERSONAL_DATA_DEMO_CREDITOR": "Schuldeiser",
    "PERSONAL_DATA_DEMO_ENTERPRISE": "Onderneming",
    "PERSONAL_DATA_DEMO_SITE": "Locatie",
    "PERSONAL_DATA_DEMO_THIRD_PARTY": "Aanbieder",
    "PERSONAL_DATA_DEMO_USER": "Gebruiker",
    "PERSONAL_DATA_DEMO_P1": "Wanneer u de site {clientWebsite} (hierna de “Site” genoemd) bezoekt en de dienst WL Bill Pay & Match gebruikt, zal Worldline France (entiteit belast met de exploitatie van de Dienst en vereenvoudigde naamloze vennootschap met een kapitaal van 58.061.383,17 euro, waarvan hoofdkantoor is gevestigd op 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, geregistreerd onder nummer 509 750 105 RCS Pontoise, vertegenwoordigd door Madame Claude France, naar behoren gemachtigd voor de doeleinden hiervan) is in staat om in zijn hoedanigheid persoonlijke gegevens over u te verzamelen en te gebruiken als onderaannemer namens de gegevensbeheerder, DEMO Holding, een vereenvoudigde naamloze vennootschap met één aandeelhouder met een kapitaal van 7.700.000 euro, waarvan het hoofdkantoor is gevestigd op 13 QUAI ALPHONSE LE GALLO 92100 BOULOGNE-BILLANCOURT en geregistreerd in de Nanterre Trade and Companies Registreer u onder het nummer 642 020 887. Worldline verbindt zich ertoe persoonsgegevens te verwerken in strikte overeenstemming met de Europese algemene verordening gegevensbescherming (EU-verordening 2016. /679 van 27 april 2016) en wet nr. 78-17 van 6 januari 1978 zoals gewijzigd met betrekking tot gegevensverwerking, bestanden en vrijheden.",
    "PERSONAL_DATA_DEMO_P2": "Deze pagina informeert u over hoe uw persoonlijke gegevens op de Site worden beschermd en verwerkt en is een integraal onderdeel van de Algemene Gebruiksvoorwaarden van de WL Bill Pay & Match-service.",
    "PERSONAL_DATA_DEMO_DEFINITIONS": "1. Definities",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P1": "«{creditor}»: verwijst naar het bedrijf dat de dienst gebruikt voor het verstrekken en betalen van facturen die debiteuren moeten betalen. In het geval van de Site, {clientWebsite}, is de Crediteur het bedrijf DEMO Holding, een vereenvoudigde naamloze vennootschap met één aandeelhouder met een kapitaal van 7.700.000 euro, waarvan het hoofdkantoor is gevestigd op 13 QUAI ALPHONSE LE GALLO 92100 BOULOGNE-BILLANCOURT, geregistreerd in het Handels- en Vennootschapsregister van Nanterre onder nummer 881 926 307.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P2": "«{debtor}»: verwijst naar de cliënt, natuurlijke of rechtspersoon, van de Crediteur en Debiteur van laatstgenoemde. De Schuldenaar heeft een gebruikersaccount aangemaakt op de Site en maakt gebruik van de Dienst door te worden geïdentificeerd aan de hand van een door de Schuldeiser doorgegeven identificatiemiddel.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P3": "«{thirdParty}»: duidt de entiteit aan die verantwoordelijk is voor de ontwikkeling en werking van de WL Bill Pay & Match Service (WL BPM), in dit geval Worldline.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P4": "«{service}»: verwijst naar de WL Bill Pay & Match (WL BPM) Dienst in zijn huidige en toekomstige versie, evenals eventuele wijzigingen waaraan deze mogelijk onderhevig is.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P5": "«{user}»: duidt een schuldenaar aan of elke natuurlijke persoon die handelt namens een schuldenaar (werknemer, accountantskantoor, erkende managementvereniging, erkend managementcentrum, holdingmaatschappij, enz.), die een account Gebruiker op de Site heeft aangemaakt en die de Dienst gebruikt.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING": "2. Doeleinden van de verwerking van persoonsgegevens",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P1": "Persoonsgegevens worden verwerkt voor welbepaalde, expliciete en legitieme doeleinden.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P2": "Ze zijn bedoeld om de betaling van facturen door de debiteur en de crediteur mogelijk te maken, de gedane betalingen te volgen, toegang te krijgen tot de betalingsgeschiedenis en sms- en/of e-mailmeldingen te verzenden voor betaling tijdens de aanbetalings- en saldofase. evenals voor herinneringen per e-mail/sms-meldingen als de debiteur de betaling(en) niet heeft betaald.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P3": "Uw bedrijf van :",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P4": "Betaling van rekeningen uitvoeren",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P5": "Aan de schuldeiser van:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P6": "Beheer primers",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P7": "Volg de uitgevoerde betalingen",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P8": "Voer e-mail-/sms-meldingen uit",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED": "3. Aard van de verzamelde persoonsgegevens",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P1": "Worldline waarborgt het principe van het minimaliseren van de verzamelde gegevens. Bijgevolg zijn de verzamelde persoonsgegevens adequaat, ter zake dienend en strikt beperkt tot wat nodig is met betrekking tot de doeleinden waarvoor ze worden verwerkt.",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P2": "De categorieën van verzamelde persoonsgegevens zijn als volgt:",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P3": "Identiteits- en contactgegevens van de Gebruiker en/of Crediteur van de dienst WL Bill Pay & Match",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P4": "Bankgegevens (wanneer deze identificeerbare rechtspersonen betreffen en direct of indirect herleidbaar zijn tot een natuurlijk persoon)",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING": "4. Rechtsgrondslagen voor de verwerking van persoonsgegevens",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P1": "De wettelijke basis voor de verwerking van uw gegevens door Worldline is de juridische uitvoering van het contract tussen DEMO en de gebruiker.",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P2": "De uitvoering van de overeenkomst die uw onderneming of de onderneming waarvoor u werkt, en de schuldeiser bindt.",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P3": "Het voldoen aan een wettelijke verplichting voor de schuldeiser.",
    "PERSONAL_DATA_DEMO_RECIPIENTS": "5. Ontvangers van persoonsgegevens",
    "PERSONAL_DATA_DEMO_RECIPIENTS_P1": "Onze eigen Wordline, die toegang heeft tot de données, cellen is een van de meest voorkomende zendt uw créancier uit.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD": "6. Duur van opslag en verwijdering van verzamelde persoonlijke gegevens",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P1": "Uw persoonsgegevens worden actief bewaard gedurende een periode van 12 maanden vanaf het einde van de contractuele relatie tussen de Schuldenaar en de Schuldeiser, om:",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P2": "enerzijds om de Creditor in staat te stellen de betalingsgeschiedenis te volgen",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P3": "anderzijds om te voldoen aan wettelijke verplichtingen ter bestrijding van het witwassen van geld en de financiering van terrorisme en ter bestrijding van fraude.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P4": "Aan het einde van de bewaartermijn worden ze automatisch verwijderd.",
    "PERSONAL_DATA_DEMO_LOCATION": "7. Locatie van uw persoonlijke gegevens",
    "PERSONAL_DATA_DEMO_LOCATION_P1": "De persoonlijke gegevens die op de Site worden verwerkt, worden opgeslagen binnen de Europese Unie.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS": "8. Uw rechten over uw persoonlijke gegevens",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P1": "In overeenstemming met de bepalingen van de Algemene Verordening Gegevensbescherming (AVG) en de gewijzigde Wet bescherming persoonsgegevens heeft u recht op toegang, rectificatie en verwijdering van alle persoonsgegevens die u betreffen. U heeft ook het recht om de verwerking en overdraagbaarheid van persoonsgegevens die u betreffen te beperken tot een derde partij, evenals het recht om richtlijnen vast te stellen met betrekking tot het lot van persoonsgegevens die u betreffen na uw overlijden.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P2": "Om uw rechten uit te oefenen, kunt u contact met ons opnemen door uw identiteit te bewijzen (kopie van uw geldig identiteitsbewijs), per e-mail op het adres {clientServiceMail} of per aangetekende brief met ontvangstbevestiging aan het volgende adres:",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P3": "Worldline",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P4": "A l’attention du Service WL Bill Pay & Match",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P5": "River Ouest, 80 quai Voltaire",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P6": "95870 Bezons",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P7": "Ten slotte heeft u het recht om in beroep te gaan bij de Nationale Commissie voor Informatietechnologie en Vrijheden (CNIL) in geval van overtreding van de regelgeving die van toepassing is op de bescherming van persoonsgegevens en in het bijzonder de AVG. Wij nodigen u uit om vóór elk verhaal contact met ons op te nemen, zodat wij al het mogelijke kunnen doen om elk mogelijk probleem op te lossen.",
    "PERSONAL_DATA_DEMO_PROTECTION": "9. Bescherming van uw persoonlijke gegevens",
    "PERSONAL_DATA_DEMO_PROTECTION_P1": "Worldline verbindt zich ertoe passende technische en organisatorische maatregelen te treffen om de veiligheid en vertrouwelijkheid van uw persoonsgegevens te garanderen en deze in het bijzonder te beschermen tegen toegang, manipulatie, wijziging, verlies of zelfs vernietiging door onbevoegde derden.",
    "PERSONAL_DATA_DEMO_PROTECTION_P2": "Het beveiligde HTTPS-protocol wordt gebruikt voor alle uitwisselingen tussen uw browser en de site.",
    "PERSONAL_DATA_DEMO_COOKIES": "10. Gebruik van cookies",
    "PERSONAL_DATA_DEMO_COOKIES_P1": "10.1 Wat is een cookie?",
    "PERSONAL_DATA_DEMO_COOKIES_P2": "Een cookie is een klein bestand met letters en cijfers dat naar uw computer wordt gedownload wanneer u bepaalde websites bezoekt.",
    "PERSONAL_DATA_DEMO_COOKIES_P3": "Het doel van een cookie is om, wanneer het door een browser wordt gelezen, een website te helpen de dienstverlening te verbeteren.",
    "PERSONAL_DATA_DEMO_COOKIES_P4": "De cookie bevat verschillende gegevens: de naam van de server die deze heeft geplaatst, de levensduur ervan en een identificatiemiddel in de vorm van een uniek nummer.",
    "PERSONAL_DATA_DEMO_COOKIES_P5": "10.2 Wie kan cookies in uw browser installeren en met welk doel?",
    "PERSONAL_DATA_DEMO_COOKIES_P6": "Worldline kan cookies in uw browser installeren om een optimale werking van de website te garanderen.",
    "PERSONAL_DATA_DEMO_COOKIES_P7": "10.3 Welke soorten cookies worden op de site gebruikt en hun levensduur?",
    "PERSONAL_DATA_DEMO_COOKIES_P8": "Wanneer u onze site bezoekt, kunnen we een bepaald type cookie op uw terminal plaatsen: cookies die strikt noodzakelijk zijn voor de goede werking van de site. Deze cookies zijn essentieel voor het surfen op onze site, omdat ze deze helpen beschermen en u in staat stellen de belangrijkste functies ervan te gebruiken.",
    "PERSONAL_DATA_DEMO_COOKIES_P9": "Deze cookies worden automatisch verwijderd wanneer de gebruiker de sessie sluit of de pagina sluit.",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P1": "Supplement van toepassing op de Worldline SPS-dienst",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P2": "(Ruimte voor het aanmaken en ondertekenen van uw SEPA-domiciliëring)",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P3": "Deze toeslag geldt voor de SPS-service. Het vormt een aanvulling op de voorgaande bepalingen met betrekking tot de verwerkingsmethoden van uw persoonlijke gegevens die specifiek zijn voor de SPS-dienst en maakt integraal deel uit van de bijzondere gebruiksvoorwaarden van de SPS-dienst.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING": "1. Doeleinden van de verwerking van persoonsgegevens",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P1": "Persoonsgegevens worden verwerkt voor specifieke, expliciete en legitieme doeleinden.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P2": "Ze zijn bedoeld om uw bedrijf in staat te stellen:",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P3": "Je rekeningen betalen",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P4": "En om de Schuldeiser toe te staan:",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P5": "Om uw authenticatie te garanderen als onderdeel van het elektronische handtekeningproces.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED": "2. Aard van de verzamelde persoonsgegevens",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P1": "De verzamelde persoonsgegevens zijn strikt noodzakelijk voor het doel dat met de verzameling wordt nagestreefd. Worldline waarborgt het principe van het minimaliseren van de verzamelde gegevens.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P2": "Worldline verzamelt uitsluitend de volgende categorieën gegevens die strikt noodzakelijk zijn om de doeleinden van de verwerking te verwezenlijken:",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P3": "Identiteit, contactgegevens",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD": "3. Duur van bewaring en verwijdering van verzamelde persoonlijke gegevens",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD_P1": "Uw persoonsgegevens worden actief bewaard voor de duur die nodig is voor de uitvoering van de elektronisch ondertekende mandaten, verlengd met de verjaringstermijn van 5 jaar. Aan het einde van de bewaartermijn worden ze automatisch verwijderd."
  },
  "it-IT": {
    "PERSONAL_DATA": "Dati personali",
    "PERSONAL_DATA_DEMO_CREDITOR": "Creditore",
    "PERSONAL_DATA_DEMO_ENTERPRISE": "Impresa",
    "PERSONAL_DATA_DEMO_SITE": "Luogo",
    "PERSONAL_DATA_DEMO_THIRD_PARTY": "Terzo",
    "PERSONAL_DATA_DEMO_USER": "Utente - Utente finale",
    "PERSONAL_DATA_DEMO_P1": "Durante la navigazione sul sito web {clientWebsite} (di seguito denominato « Sito ») e l'utilizzo del servizio WL Bill Pay & Match, Worldline France (soggetto incaricato della gestione del servizio e società per azioni semplificata con un capitale di 58.061.383,17 euro, il cui con sede legale in 80 quai Voltaire “Immeuble River Ouest” 95870 BEZONS, iscritta al numero 509 750 105 RCS Pontoise, rappresentata da Madame Claude France, debitamente autorizzata per le finalità del presente documento) è tenuta a raccogliere e utilizzare i dati personali che la riguardano in qualità di subappaltatore per conto del titolare del trattamento, ELTO Holding, società per azioni semplificata con un unico socio con un capitale di euro 7.700.000, con sede legale in 13 QUAI ALPHONSE LE GALLO 92100 BOULOGNE-BILLANCOURT ed iscritta al Registro del Commercio e delle Imprese di Nanterre sotto numero 642 020 887. Worldline si impegna a trattare i dati personali nel rigoroso rispetto del regolamento generale europeo sulla protezione dei dati (regolamento UE 2016/679 del 27 aprile 2016) e della legge n. 78-17 del 6 gennaio 1978 modificata relativa alla L'informatica, i file e le libertà.",
    "PERSONAL_DATA_DEMO_P2": "Questa pagina ti informa su come i tuoi dati personali sono protetti e trattati sul sito web e fa parte integrante dei Termini e condizioni di Worldline Bill Pay & Match.",
    "PERSONAL_DATA_DEMO_DEFINITIONS": "1. Definizioni",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P1": "« {creditore} »: indica la società che utilizza il servizio di fornitura e pagamento delle fatture che i Debitori sono tenuti a pagare. Nel caso del Sito {clientWebsite}, il Creditore è {clientITName}, società per azioni semplificata con un unico socio, con sede legale in 13 QUAI ALPHONSE LE GALLO 92100 BOULOGNE-BILLANCOURT e iscritta al Registro del Commercio e Imprese di Nanterre con il numero 881 926 307.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P2": "« {debitore} »: significa il cliente, persona fisica o giuridica, del Creditore e Debitore di quest'ultimo. Il Debitore ha creato un account utente sul Sito e utilizza il Servizio essendo identificato tramite un identificatore trasmesso dal Creditore.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P3": "« {terzaParte} »: indica il soggetto responsabile dello sviluppo e del funzionamento dei servizi WL Bill Pay & Match (WL BPM); in questo caso è Worldline.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P4": "« {servizio} »: indica il Servizio WL Bill Pay & Match (WL BPM) nella sua versione attuale e futura nonché le eventuali modifiche alle quali potrebbe essere soggetto.",
    "PERSONAL_DATA_DEMO_DEFINITIONS_P5": "« {utente} »: indica un Debitore o qualsiasi persona fisica che agisce per conto di un Debitore (dipendente, società di contabilità, associazione di gestione autorizzata, centro di gestione autorizzato, società holding, ecc.), che ha creato un account Utente sul Sito e chi utilizza il Servizio.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING": "2. Finalità del trattamento dei dati personali",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P1": "I dati personali sono trattati per finalità determinate, esplicite e legittime.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P2": "Hanno lo scopo di consentire il pagamento delle fatture da parte del debitore e del creditore per seguire i pagamenti effettuati, accedere allo storico dei pagamenti ed effettuare solleciti tramite notifiche e-mail/SMS per il pagamento durante le fasi di fattura di acconto e saldo prima dell'installazione nonché per solleciti tramite e-mail/avvisi via SMS se il debitore non ha pagato ili pagamenti.",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P3": "La tua azienda:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P4": "Presentazione e pagamento delle fatture tramite il nostro portale",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P5": "Creditore:",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P6": "Gestire le fatture e i primer delle note di creditos",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P7": "Tieni traccia dei pagamenti effettuati",
    "PERSONAL_DATA_DEMO_PURPOSES_DATA_PROCESSING_P8": "Notifiche tramite e-mail e messaggi di testo",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED": "3. Natura dei dati personali raccolti",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P1": "Worldline garantisce il principio di minimizzazione dei dati raccolti. Di conseguenza, i dati personali raccolti sono adeguati, pertinenti e strettamente limitati a quanto necessario rispetto alle finalità per le quali sono trattati.",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P2": "Le categorie di dati personali raccolti sono le seguenti:",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P3": "Identità e dati di contatto dell'Utente e/o del Creditore del servizio WL Bill Pay & Match",
    "PERSONAL_DATA_DEMO_NATURE_DATA_COLLECTED_P4": "Coordinate bancarie (quando riguardano persone giuridiche identificabili e possono essere ricondotte direttamente o indirettamente a una persona fisica)",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING": "4. Basi giuridiche del trattamento dei dati personali",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P1": "Il trattamento dei tuoi dati da parte di Worldline ha come base giuridica l'esecuzione legale del contratto tra DEMO e l'utente.",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P2": "L'esecuzione del contratto tra la vostra azienda o l'azienda per conto della quale lavorate e il creditore.",
    "PERSONAL_DATA_DEMO_LEGAL_BASES_PROCESSING_P3": "Adempimento di un obbligo legale per il creditore.",
    "PERSONAL_DATA_DEMO_RECIPIENTS": "5. Destinatari dei dati personali",
    "PERSONAL_DATA_DEMO_RECIPIENTS_P1": "Ad eccezione di Worldline in qualità di subappaltatore che ha accesso ai dati, questi dati possono essere trasmessi anche al Creditore.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD": "6. Periodo di conservazione e cancellazione dei dati personali raccolti",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P1": "I tuoi dati personali sono conservati in un database attivo per un periodo di 12 mesi dalla cessazione del rapporto contrattuale tra il Debitore e il Creditore, al fine di:",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P2": "da un lato, per consentire al creditore di seguire lo storico dei pagamenti",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P3": "dall’altro, per adempiere agli obblighi di legge in materia di lotta al riciclaggio e al finanziamento del terrorismo e lotta alle frodi.",
    "PERSONAL_DATA_DEMO_RETENTION_PERIOD_P4": "Verranno poi automaticamente cancellati al termine del periodo di conservazione.",
    "PERSONAL_DATA_DEMO_LOCATION": "7. Ubicazione dei tuoi dati personali",
    "PERSONAL_DATA_DEMO_LOCATION_P1": "I dati personali trattati sul Sito sono archiviati nella zona Europa.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS": "8. I tuoi diritti sui dati personali",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P1": "In conformità con le disposizioni del Regolamento generale sulla protezione dei dati (GDPR) e della legge sulla protezione dei dati modificata, avete il diritto di accesso, rettifica e cancellazione di tutti i dati personali che vi riguardano. Hai inoltre il diritto di limitare il trattamento e la portabilità dei dati personali che ti riguardano a una società terza, nonché il diritto di definire linee guida relative al destino dei dati personali che ti riguardano dopo la tua morte.",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P2": "Per esercitare i tuoi diritti puoi contattarci, dimostrando la tua identità (copia del tuo documento di identità in corso di validità), tramite email all'indirizzo di posta elettronica {clientServiceMail} o tramite lettera:",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P3": "Worldline",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P4": "A l’attention du Service WL Bill Pay & Match",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P5": "River Ouest, 80 quai Voltaire",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P6": "95870 Bezons",
    "PERSONAL_DATA_DEMO_YOUR_RIGHTS_P7": "Infine, hai il diritto di ricorrere alla Commissione nazionale francese per l'informatica e le libertà (CNIL) in caso di violazione della normativa applicabile sulla protezione dei dati personali e in particolare del GDPR. Vi invitiamo a contattarci prima di qualsiasi ricorso, in modo da fare tutto il possibile per risolvere ogni possibile problema.",
    "PERSONAL_DATA_DEMO_PROTECTION": "9. Protezione contro i tuoi dati personali",
    "PERSONAL_DATA_DEMO_PROTECTION_P1": "Worldline si impegna ad attuare le misure tecniche e organizzative adeguate al fine di garantire la sicurezza e la riservatezza dei vostri dati personali e in particolare per proteggerli contro l'accesso, la manipolazione, l'alterazione, la perdita o anche la distruzione da parte di terzi non autorizzati.",
    "PERSONAL_DATA_DEMO_PROTECTION_P2": "Per tutti gli scambi tra il tuo browser e il sito web viene utilizzato il protocollo protetto HTTPS.",
    "PERSONAL_DATA_DEMO_COOKIES": "10. Utilizzo dei cookie",
    "PERSONAL_DATA_DEMO_COOKIES_P1": "10.1 Che cosa sono i cookie?",
    "PERSONAL_DATA_DEMO_COOKIES_P2": "Un cookie è unconsentire piccolo file di lettere e numeri scaricato sul tuo computer quando accedi a determinati siti web.",
    "PERSONAL_DATA_DEMO_COOKIES_P3": "Lo scopo di un cookie è, quando letto da un browser, aiutare un sito web a migliorare il servizio fornito.",
    "PERSONAL_DATA_DEMO_COOKIES_P4": "Il cookie contiene diversi dati: il nome del server che lo ha depositato, la sua durata e un identificatore sotto forma di numero univoco.",
    "PERSONAL_DATA_DEMO_COOKIES_P5": "10.2 Chi può installare i cookie sul tuo browser, e a quale scopo?",
    "PERSONAL_DATA_DEMO_COOKIES_P6": "Worldline può installare cookie sul tuo browser per garantire che il sito web funzioni in modo ottimale.",
    "PERSONAL_DATA_DEMO_COOKIES_P7": "10.3 Quali tipi di cookie vengono inseriti nel sito e la loro durata?",
    "PERSONAL_DATA_DEMO_COOKIES_P8": "Quando navighi sul nostro sito, potremmo dover installare un certo tipo di cookie sul tuo computer: cookie strettamente necessari per il corretto funzionamento del sito. Questi cookie sono essenziali per navigare sul nostro sito perché aiutano a proteggerlo e ti consentono di utilizzare le sue principali funzionalità.",
    "PERSONAL_DATA_DEMO_COOKIES_P9": "Questi cookie vengono automaticamente cancellati quando l'utente si disconnette o la pagina viene chiusa.",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P1": "Supplemento applicabile al servizio Worldline Sepa Payment Suite (WL SPS).",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P2": "(Creazione di addebito diretto SEPA e spazio di firma)",
    "PERSONAL_DATA_DEMO_SPS_SUPPLEMENT_P3": "Questo capitolo si applica solo alla soluzione WL SPS per l'addebito diretto SEPA. Essa integra le disposizioni sopra riportate relative alle modalità di trattamento dei tuoi dati personali specifiche del servizio WL SPS e costituisce parte integrante delle Condizioni Particolari di Utilizzo del servizio WL SPS.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING": "1. Finalità del trattamento dei dati personali",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P1": "I dati personali sono trattati per finalità determinate, esplicite e legittime.",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P2": "Hanno lo scopo di consentire alla tua impresa",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P3": "Pagamento delle fatture con WL SPS",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P4": "E per consentire al Creditore:",
    "PERSONAL_DATA_DEMO_SPS_PURPOSES_DATA_PROCESSING_P5": "Garantisci la tua autenticazione come parte del processo di firma elettronica.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED": "2. Natura dei dati personali raccolti",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P1": "The personal data collected is strictly necessary for the purpose of the collection. Worldline ensures the principle of minimizing the data collected.",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P2": "Worldline raccoglie solo le seguenti categorie di dati strettamente necessari per raggiungere le finalità del trattamento:",
    "PERSONAL_DATA_DEMO_SPS_NATURE_DATA_COLLECTED_P3": "Identità, dettagli di contatto",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD": "3. Periodo di conservazione e cancellazione dei dati personali raccolti",
    "PERSONAL_DATA_DEMO_SPS_RETENTION_PERIOD_P1": "I Suoi dati personali sono conservati in un database attivo per la durata necessaria all'esecuzione dei mandati firmati elettronicamente, prorogato del termine di prescrizione di 5 anni. Verranno poi automaticamente cancellati al termine del periodo di conservazione."
  }
}
</i18n>