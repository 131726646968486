<template>
  <v-container>
    <component :is="componentName">
    </component>
    <FabScrollToTop/>
  </v-container>
</template>

<script>
import FabScrollToTop from '@/components/common/FabScrollToTop';
import AccorPersonalData from '@/components/common/personal-data/AccorPersonalData';
import DemoPersonalData from '@/components/common/personal-data/DemoPersonalData';
import FrindigogpPersonalData from '@/components/common/personal-data/FrindigogpPersonalData';
import FrindigosigcPersonalData from '@/components/common/personal-data/FrindigosigcPersonalData';
import IndigoPersonalData from '@/components/common/personal-data/IndigoPersonalData';
import MpsPersonalData from '@/components/common/personal-data/MpsPersonalData';
import IqeraPersonalData from '@/components/common/personal-data/IqeraPersonalData';
import BloctelPersonalData from '@/components/common/personal-data/BloctelPersonalData';
import environmentService from '@/services/environmentService';

export default {
  components: {
    FabScrollToTop,
    IndigoPersonalData,
    FrindigosigcPersonalData,
    FrindigogpPersonalData,
    DemoPersonalData,
    AccorPersonalData,
    MpsPersonalData,
    IqeraPersonalData,
    BloctelPersonalData
  },
  data: () => ({
    realm: environmentService.realm,
    availablePersonalData: [
      'IndigoPersonalData',
      'FrindigosigcPersonalData',
      'FrindigogpPersonalData',
      'DemoPersonalData',
      'AccorPersonalData',
      'MpsPersonalData',
      'IqeraPersonalData',
      'BloctelPersonalData'
    ]
  }),
  computed: {
    componentName () {
      const alnumRealmName = this.realm.replace(/\W/g, '');
      return alnumRealmName.charAt(0).toUpperCase() + alnumRealmName.slice(1) + 'PersonalData';
    }
  },
  beforeMount () {
    if (!this.availablePersonalData.includes(this.componentName)) {
      this.$router.push({ name: 'Error' });
    }
  }
};
</script>

<style scoped>

</style>
