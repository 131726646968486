<!-- eslint-disable -->
<!-- COMMENTAIRE DU DESSUS À SUPPRIMER QUAND LA PAGE SERA RÉELLEMENT IMPLÉMENTÉE -->
<template>
  <v-container>
    <v-alert
      color="primary"
      text
      border="right"
    >
      <i18n path="PAGE_ABOUT" tag="span">
        <template v-slot:about>
          <strong>{{ $t('ABOUT') }}</strong>
        </template>
      </i18n>

      <v-spacer></v-spacer>
      <span class="caption">— {{ now | dateTime }}, {{ 12548.25 | number }}, {{ 12548.25 | currency('EUR') }}, {{ now | fromNow }}</span>
    </v-alert>

    <v-row no-gutters>

      <v-col sm="12" md="6">
        <v-card
          class="mx-auto"
          max-width="600"
        >
          <v-card-title>
            <v-icon
              :color="checking ? 'red lighten-2' : 'indigo'"
              class="mr-12"
              size="64"
              @click="takePulse"
            >
              mdi-heart-pulse
            </v-icon>
            <v-row align="start">
              <div class="caption grey--text text-uppercase">
                Heart rate
              </div>
              <div>
                <span
                  class="display-2 font-weight-black"
                  v-text="avg || '—'"
                ></span>
                <strong v-if="avg">BPM</strong>
              </div>
            </v-row>

            <v-spacer></v-spacer>

            <v-btn
              icon
              class="align-self-start"
              size="28"
            >
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-card-title>

          <v-sheet color="transparent">
            <v-sparkline
              :key="String(avg)"
              :smooth="16"
              :line-width="3"
              :value="heartbeats"
              auto-draw
              stroke-linecap="round"
            ></v-sparkline>
          </v-sheet>
        </v-card>
          </v-col>

          <v-col sm="12" md="6">
            <v-card
              class="mt-4 mx-auto"
              max-width="400"
            >
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="red"
                elevation="12"
                max-width="calc(100% - 32px)"
              >
                <v-sparkline
                  :labels="labels"
                  :value="value"
                  color="white"
                  line-width="2"
                  padding="16"
                ></v-sparkline>
              </v-sheet>

              <v-card-text class="pt-0">
                <div class="title font-weight-light mb-2">
                  User Registrations
                </div>
                <div class="subheading font-weight-light grey--text">
                  Last Campaign Performance
                </div>
                <v-divider class="my-2"></v-divider>
                <v-icon
                  class="mr-2"
                  small
                >
                  mdi-clock
                </v-icon>
                <span class="caption grey--text font-weight-light">last registration 26 minutes ago</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col cols="12">
            <v-card>
              <v-card-text>

                <v-progress-linear
                  color="orange"
                  reverse
                  height="12"
                  value="30"
                ></v-progress-linear>

                <v-data-table
                  :headers="headers"
                  :items="invoices"
                  show-select
                  :loading="loading"
                ></v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  </v-container>
</template>

<script>
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';

import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';

const exhale = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {
  mixins: [dateMixin, numberMixin],
  data: () => ({
    now: new Date(),
    checking: false,
    heartbeats: [],
    labels: [
      '12am',
      '3am',
      '6am',
      '9am',
      '12pm',
      '3pm',
      '6pm',
      '9pm'
    ],
    value: [
      200,
      675,
      410,
      390,
      310,
      460,
      250,
      240
    ],
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Invoice Number',
        value: 'number'
      },
      {
        text: 'Amount Excluding Tax',
        value: 'amountExclusiveOfTax',
        align: 'end'
      }],
    invoices: [],
    loading: false
  }),
  computed: {
    avg () {
      const sum = this.heartbeats.reduce((acc, cur) => acc + cur, 0);
      const length = this.heartbeats.length;
      if (!sum && !length) return 0;
      return Math.ceil(sum / length);
    }
  },
  created () {
    this.takePulse(false);
  },
  async beforeMount () {
    this.loading = true;
    try {
      const { data } = await invoiceService.getInvoicesToValidate('223344556_987654321');
      this.invoices = invoiceMapper.mapAll(data.items);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    heartbeat () {
      return Math.ceil(Math.random() * (120 - 80) + 80);
    },
    async takePulse (inhale = true) {
      this.checking = true;

      inhale && await exhale(1000);

      this.heartbeats = Array.from({ length: 20 }, this.heartbeat);
      this.checking = false;
    }
  }
};
</script>

<style scoped>
.v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>