<template>
  <v-container>
    <h1 class="text-center">{{ $t('FAQ') }}</h1>

    <v-divider inset class="my-6"></v-divider>

    <v-row>
      <div class="table-of-content col-12 col-md-4">
        <h2>{{ $t('FAQ_TABLE_OF_CONTENT') }}</h2>
        <ul class="mt-5">
          <li
            class="mb-5"
            v-for="category in faqText"
            :key="category.category"
          >
            <h3><a @click="scrollMeTo(category.category)">{{ category.category }}</a></h3>
            <ul>
              <li
                v-for="question in category.questions"
                :key="question.question"
              >
              <a @click="scrollMeTo(question.question)">{{ question.question }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-8">
        <ul class="mt-5">
        <li
          class="mb-5"
          v-for="category in faqText"
          :key="category.category"
        >
          <h3 :ref="category.category">{{ category.category }}</h3>
          <ul>
            <li
              v-for="question in category.questions"
              :key="question.question"
            >
            <h4 :ref="question.question" class="mb-2">{{ question.question }}</h4>
            <p v-html="question.answer"></p>
            </li>
          </ul>
        </li>
        </ul>
      </div>
    </v-row>
    <FabScrollToTop/>

  </v-container>
</template>

<script>
import FabScrollToTop from '@/components/common/FabScrollToTop.vue';

export default {
  components: {
    FabScrollToTop
  },
  name: 'Home',
  computed: {
    faqText () {
      return this.$i18n.messages[this.$i18n.locale].FAQ_TEXT;
    }
  },
  methods: {
    scrollMeTo (refName) {
      const element = this.$refs[refName];
      const top = element[0].offsetTop;
      window.scrollTo(0, top - 5);
    }
  }
};
</script>

<style scoped>
  .table-of-content a {
    text-decoration: none;
  }

  .table-of-content li, .table-of-content h3 {
    font-size: 0.9375rem;
  }
</style>