<template>
  <v-container>
    <component :is="componentName">
    </component>
    <FabScrollToTop/>
  </v-container>
</template>

<script>
import FabScrollToTop from '@/components/common/FabScrollToTop';
import AccorTermsAndConditions from '@/components/common/terms-and-conditions/AccorTermsAndConditions';
import DemoTermsAndConditions from '@/components/common/terms-and-conditions/DemoTermsAndConditions';
import FrindigogpTermsAndConditions from '@/components/common/terms-and-conditions/FrindigogpTermsAndConditions';
import FrindigosigcTermsAndConditions from '@/components/common/terms-and-conditions/FrindigosigcTermsAndConditions';
import IndigoTermsAndConditions from '@/components/common/terms-and-conditions/IndigoTermsAndConditions';
import MpsTermsAndConditions from '@/components/common/terms-and-conditions/MpsTermsAndConditions';
import IqeraTermsAndConditions from '@/components/common/terms-and-conditions/IqeraTermsAndConditions';
import BloctelTermsAndConditions from '@/components/common/terms-and-conditions/BloctelTermsAndConditions';
import environmentService from '@/services/environmentService';

export default {
  components: {
    FabScrollToTop,
    IndigoTermsAndConditions,
    FrindigosigcTermsAndConditions,
    FrindigogpTermsAndConditions,
    DemoTermsAndConditions,
    AccorTermsAndConditions,
    MpsTermsAndConditions,
    IqeraTermsAndConditions,
    BloctelTermsAndConditions
  },
  data: () => ({
    realm: environmentService.realm,
    availablePersonalData: [
      'IndigoTermsAndConditions',
      'FrindigosigcTermsAndConditions',
      'FrindigogpTermsAndConditions',
      'DemoTermsAndConditions',
      'AccorTermsAndConditions',
      'MpsTermsAndConditions',
      'IqeraTermsAndConditions',
      'BloctelTermsAndConditions'
    ]
  }),
  computed: {
    componentName () {
      const alnumRealmName = this.realm.replace(/\W/g, '');
      return alnumRealmName.charAt(0).toUpperCase() + alnumRealmName.slice(1) + 'TermsAndConditions';
    }
  },
  beforeMount () {
    if (!this.availablePersonalData.includes(this.componentName)) {
      this.$router.push({ name: 'Error' });
    }
  }
};
</script>